// === THIS FILE IS SLATED TO REPLACE THE OLD 'contentInterfaces' FILE AND IMPLEMENTS A STRONGER TYPESCRIPT INTERFACE & REACT-HOOK-FORM INSTEAD OF REDUX FORM SLICE ===
import { GetInstanceInfoByInstanceIdResponse } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { Path, UseFormSetValue } from 'react-hook-form';
import Activity from 'src/interfaces/activityInterfaces';
import Pipeline from 'src/interfaces/pipelineInterfaces';
import { HelpPanels } from '..';
import { DataAttributes } from 'src/commons/dataAttributes';
import { CodeEditorProps, MultiselectProps, SelectProps } from '@amzn/awsui-components-react';
import { RulesProp } from './Rules';
import { editorInputDefinition } from 'src/interfaces/inputInterfaces';
import { UnitType } from 'src/commons';

// TODO: Eventually refactor Data/Resource/API types into single interface.
export interface ResourceType {
  activity: Activity;
  pipeline: Pipeline;
  instance: GetInstanceInfoByInstanceIdResponse & { duration: number };
}

export enum DisplayMode {
  View = 'view',
  Edit = 'edit',
  Loading = 'loading',
}

/** This type takes in a resource's name (key) and returns all the Paths (dot-separated for nested objects) of that resource's corresponding interface using `react-hook-form`'s helpful `Path<>` type
 * Used in InterfaceFromPaths to invert a flattened Path<> back to a structured/nested interface
 *
 * For example, if resource `'a'` has interface `{ a: string; b: number; c: {d: string, e: number}; }`
 * then the `ResourcePath<'a'>` will be `'a' | 'b' | 'c' | 'c.d' | 'c.e'` */
export type ResourcePath<RType extends keyof ResourceType> = Path<ResourceType[RType]>;

/** This type takes takes in a path string and returns a tuple of that string split on dots
 * Used in InterfaceFromPaths to invert a flattened Path<> back to a structured/nested interface
 *
 * For example `Split<'a.b.c'>` = ['a', 'b', 'c']`*/
export type Split<Path extends string> = Path extends `${infer P1}.${infer P2}` ? [P1, ...Split<P2>] : [Path];

/**  Takes in a string tuple type as well as a particular destination type, and returns a deeply nested object interface based on those keys.
 * Used in InterfaceFromPaths to invert a flattened Path<> back to a structured/nested interface
 * For example, `Deepen<['a','b','c'], string> = { a: { b: {c: string } };` */
export type Deepen<S extends string[], T = any> = S extends [string, ...infer Keys]
  ? Keys extends [string, ...string[]]
    ? { [key in S[0]]: Deepen<Keys, T> }
    : { [key in S[0]]: T }
  : {};

/** This type takes in a union of types and returns their intersection, allowing the creation of more complex and strict interfaces from unions of other interfaces
 * Used in InterfaceFromPaths to invert a flattened Path<> back to a structured/nested interface
 *
 * Typescript Union Types Documentation: https://www.typescriptlang.org/docs/handbook/2/everyday-types.html#union-types
 * Implementation based on https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type > solution (https://stackoverflow.com/a/50375286) */
export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

/** Converts a list of dot-separated paths (type) to the interface that they define. This allows us to infer the nested interface of an object from its paths.
 * This functions as a (simplified) inverse of the `react-hook-form` `Path<>` type
 *
 * For example, `InterfaceFromPaths<('a' | 'b' | 'c.d' | 'c.e')[]>` will correspond to an interface like (using default Deepen type `any`):

 *      {
 *        a: any;
 *        b: any;
 *        c: {
 *          d: any;
 *          e: any;
 *        }
 *      } */
export type InterfaceFromPaths<Paths extends string[]> = Paths[number] extends string
  ? UnionToIntersection<Deepen<Split<Paths[number]>>>
  : {};

/** The interface for a Content's `disabledOn` prop. See  `useDisabledOn` below for more details */
export interface iDisabledOn<DisableOnPaths extends string[]> {
  paths: DisableOnPaths;
  condition: (values: InterfaceFromPaths<DisableOnPaths>) => boolean;
}

/** The base interface for `<Content>` components which can render view/edit fields for a particular resource type and ID.
 * For an overview, see the [README](./README.md). For usage example, see the [How-To Guide](./HowToGuide.md) */
export interface iContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>
  extends DataAttributes {
  // === Required Props: ===

  /** The type of resource to be rendered in the Content, e.g. `activity`, `pipeline`, etc. */
  resourceType: RType;

  /** The dot-separated path defining the field in a particular resource.
   * For example, for some resource R, the path of `R['key1'].key2` would be defined as `"key1.key2"`
   * This relies on a lodash-style implementation to derive paths and fetch their value.
   * Read more here: https://lodash.com/docs/4.17.15 */
  path: ResourcePath<RType>;

  /** The label to display above a form field. Can simply be a string defining the content of the field, or a more elaborate component. */
  label: React.ReactNode;

  /** The display-mode of the content. Can be `"view"` (displays the value), `"edit"` (displays an editable form field), or `"loading"` (displays a skeleton with the defined label)*/
  mode: DisplayMode;

  // === Optional Props: ===

  /** The unique ID of a particular resource, which is used to retrieve that resource from the Redux `data` slice. For example, an activityId or pipelineName. This can remain empty/undefined when creating a new resource. */
  resourceId?: string;
  /** The name of a HelpPanel component defined in `src/components/helpers/info/HelpPanels`. This will render a Polaris Info link next to the label which will open the appropriate help panel.
   * See implementational details in the `HammerstoneAppLayout.tsx`, `HelpPanels.tsx`, and `HelpPanelInfoLink.tsx` file.
   * Read more about related Polaris best-practices here:
   *    - Help System Pattern: https://cloudscape.aws.dev/patterns/general/help-system/
   *    - Help Panel Component: https://cloudscape.aws.dev/components/help-panel/?tabId=usage
   *    - Info Link Component: https://cloudscape.aws.dev/components/link/?tabId=playground&example=info-link */
  infoHelpPanel?: keyof typeof HelpPanels;

  // TODO: Eventually expand disabled and disableOn interfaces to return a `string` , allowing us to define user messages (explaining the disabled conditions) when a field is disabled
  /** Whether the field should be disabled. If you want to conditionally disable on the values of other fields, use the `disableOn` prop. */
  disabled?: boolean;

  /** Conditions whether this field is disabled based on the values of other fields. See `useDisableOn` for more details
   *
   * To do so, you must provide a list of `paths`. The component will subscribe to changes in the values at those paths.
   * Provide a `condition` function with an argument of an object defined by the values at the paths given above paths, and which returns whether the field should be disabled.
   *
   * Usage example, can find another example in the [How-To Guide](./HowToGuide.md):
   *
   * @example
   * disabledOn = {
   *    // the component subscribes to changes to `resource.a`, `resource.b`, and `resource.c.d`
   *    paths: ['a', 'b', 'c.d'],
   *    // `values` arg is an obj like { a: any; b: any; c: { d: any; }; }
   *    condition : (values) =>
   *      // The field will be disabled on the following conditions
   *      values.a==="something" || values.b < 10 || values.c.d?.length >3,
   * };
   *  */
  disableOn?: iDisabledOn<DisableOnPaths>; // Should this be accessible from both View and edit?

  // === View Mode Props: ===

  /** The default text to display in a field if its value is empty, undefined, or otherwise missing */
  missingText?: string;
  /** A function to transform a the displayed value of a field. This is useful for fields which are wrapped in custom React components, like Status, User, or Links. */
  viewTransform?: (value: any) => any;

  // === Edit Mode Props: ===

  /** The rules used to validate a particular field, OR a function mapping a label string to the rules for a field (for easier Rule customization).
   * If a particular field is disabled (either by `disabled` or `disabledOn`, then all rules are ignored.
   *
   * See the following for more information:
   * https://react-hook-form.com/docs/usecontroller/controller
   * https://react-hook-form.com/docs/useform/register#options  */
  rules?: RulesProp<ResourceType[RType]>; // Rules type

  /** A component rendered under a field's label, usually using the `<FormField description>`: (https://cloudscape.aws.dev/components/form-field/)
   * This is a good place to provide tips on how to edit a field and any validation or disabling criteria of which a user should be aware.  */
  editDescription?: React.ReactNode; // Should be Node probably?

  /** A placeholder value to show in an empty Input-style component */
  placeholder?: string;

  /** Optional - A custom onChange function, which will receive the `event` emitted by an Input-style component, a `setField` function (`field.onChange`) to directly update the current field's value,
   * and a `setValue` function to set the values of other fields (useful when updating on field should change the value of another).
   * Default behavior uses `field.onChange` to update a field's value.
   * For example:
   *
   *      onChange={(event, setField)=>{
   *        // Updates the field based on the emitted event
   *        // You may apply a transformation to the value at this step, e.g. formatting a date or phone number
   *        setField(event.detail.value);
   *      }}
   *  */
  onChange?: (
    event?: CustomEvent,
    setField?: (...event: any[]) => void,
    setValue?: UseFormSetValue<ResourceType[RType]>,
  ) => void;
}

type SelectPropsToKeep =
  | 'options'
  | 'filteringType'
  | 'onLoadItems'
  | 'statusType'
  | 'finishedText'
  | 'loadingText'
  | 'errorText';

type iSelectProps = Pick<SelectProps, SelectPropsToKeep>;
type iMultiselectProps = Pick<MultiselectProps, SelectPropsToKeep>;

/** Extends the base iContent, with the addition of certain (Multi)Select props, most notably the `options` list.
 *
 * Read more about the interfaces of Cloudscape's [`<Select>`](https://cloudscape.aws.dev/components/select/?tabId=api) and [`<Multiselect>`](https://cloudscape.aws.dev/components/multiselect/?tabId=api) */
export interface iSelectContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>
  extends iContent<RType, DisableOnPaths>,
    iSelectProps,
    iMultiselectProps {
  options: SelectProps.Options | MultiselectProps.Options;
}

/** Extends the base iContent with specific props for displaying a [`<BetterCodeEditor>`](../BetterCodeEditor.tsx) which implements a Cloudscape [`<CodeEditor>`](https://cloudscape.aws.dev/components/code-editor/?tabId=api) */
export interface iCodeContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>
  extends iContent<RType, DisableOnPaths> {
  /** The language with which syntax should be highlighted and checked */
  language: CodeEditorProps.Language;
  /** Specifies the height of the code editor document in pixels */
  editorContentHeight?: number;
  /** Unfortunately there is no implementation (yet) of placeholders on the Cloudscape Code Editor */
  placeholder?: never;
}

type RegularToggle = {
  expandable?: false | undefined;
  children?: never;
};
type ExpandableToggle = {
  expandable: true;
  children: React.ReactNode;
};

export type iToggleContent<
  RType extends keyof ResourceType,
  DisableOnPaths extends ResourcePath<RType>[] = [],
> = iContent<RType, DisableOnPaths> & {
  /** If true, wraps the Toggle content in an [`<ExpandableSection>`](https://cloudscape.aws.dev/components/expandable-section/) and renders the `children` within  */
  expandable?: boolean;
  /** Forbidden for regular toggles, required when `expandable` is true. This is the ReactNode content to render under an expanded toggle. */
  children?: React.ReactNode;
} & (RegularToggle | ExpandableToggle);

export interface iAttributeEditorContent<
  RType extends keyof ResourceType,
  DisableOnPaths extends ResourcePath<RType>[] = [],
> extends iContent<RType, DisableOnPaths> {
  /** A list of editorInputDefinitions which is used to create the definitions for both the Table (in View mode) and the AttributeEditor (in Edit mode), allowing for a unified interface.
   * Please note that react-hook-form's useFieldArray will reserves an `id` value for each object in order to track values, so DO NOT USE key="id"! */
  definitions: editorInputDefinition[];
  /** AttributeEditorContent uses react-hook-form useFieldArray's update function instead of onChange. For custom update behavior, create a custom content component */
  onChange?: never;
}

export interface iDatetimeContent<RType extends keyof ResourceType, DisableOnPaths extends ResourcePath<RType>[] = []>
  extends iContent<RType, DisableOnPaths> {
  /** Whether to include a button that updates the given value to now (UTC). If provided a UnitType, anything below that unit will be truncated (see `truncateUTCDate`). */
  nowButton?: boolean | UnitType;
}
