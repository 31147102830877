import {
  AttributeEditor,
  AttributeEditorProps,
  FormField,
  Header,
  Input,
  Select,
  Table,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IamRoleAssociation } from '@amzn/awsdwhammerstonerepository/clients/dwhammerstonerepositoryservice';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

import {
  useLdapGroups,
  useGroupName,
  useIamRoles,
  useOdinMaterialSets,
  useS3CredentialType,
  useActivityInfoCache,
} from '../../data/redux';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';
import { ReduxState } from '../../interfaces/reduxInterfaces';
import { HelpPanelInfoLink, LabeledContentSkeleton } from '../helpers';
import { useConditionalEffect, valueListToOptions } from '../../commons';
import { fetchIamRolesByGroup, fetchMaterialSets } from 'src/data/api/fetchFromAPI';
import Content, { Rules } from '../helpers/content/';
import Activity from 'src/interfaces/activityInterfaces';
import { SALTIRE } from 'src/constants/ariaLabels';
import { getContentDataAttributes } from 'src/commons/dataAttributes';
import keys from 'src/constants/hammerstoneConstantKeys';

const label = 'IAM role(s)';
const IAM_ROLES_PATH = 'config.IAM_ROLES' as const;
const placeholder = 'arn:aws:iam::0123456789012:role/role_name_placeholder';

interface iActivityCredentialContent {
  mode: DisplayMode;
  resourceId: string;
}

interface IAMRoleObj {
  type: string;
  value: string;
}

function iamRolesToItems(roles: { value: string }[]): IAMRoleObj[] {
  const items = (roles ?? []).map(({ value }) => ({
    type: 'Chain role',
    value,
  }));
  if (items.length === 0) {
    items.push({ type: 'S3 IAM role', value: '' });
  } else {
    items[0].type = 'Redshift cluster role';
    items[items.length - 1].type = 'S3 IAM role';
  }
  return items;
}

/**
 * Converts the IAM roles stored in the Redux store to options for the initial IAM Role `<Select>`
 *
 * Follows the filtering logic from the old Hammerstone site:  https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/6a73ed521ebcb835da97ffb1b4cd6a7ccea3b662/--/rails-root/app/controllers/manageactivity_controller.rb#L1833
 *
 * @param iamRolesObj An object mapping IAM arns to an IamRoleAssociation (returned from GetUserGroupIamRole RepoService API)
 * @param ldapGroups The list of LDAP groups belonging to the user (to filter out the returned roles)
 * @returns Options for the IAM Role Select component
 */
function iamRolesByGroupToOptions(iamRoles: IamRoleAssociation[], ldapGroups: string[]): OptionDefinition[] {
  const ldapGroupSet = new Set((ldapGroups ?? []).map((group) => group.toLowerCase()));

  // First filter out all invalid & disabled IAM roles
  const filteredIamRoles = (iamRoles ?? []).filter(
    (iamRole) =>
      iamRole.active && iamRole.iamRoleArn && iamRole.ldapGroup && ldapGroupSet.has(iamRole.ldapGroup.toLowerCase()),
  );

  // Then combine/aggregate them into an object mapping IAM arn to an object with a list of descriptions and ldap groups
  const combinedUniqueIamRoleArns = filteredIamRoles.reduce((accumulator, currentValue) => {
    if (!(currentValue.iamRoleArn in accumulator)) {
      accumulator[currentValue.iamRoleArn] = { ...currentValue, descriptionSet: new Set(), ldapSet: new Set() };
    }
    // Add ldap group and description to lists
    accumulator[currentValue.iamRoleArn].ldapSet.add(currentValue.ldapGroup);
    accumulator[currentValue.iamRoleArn].descriptionSet.add(currentValue.roleDescription);
    return accumulator;
  }, {} as { [arn: string]: IamRoleAssociation & { descriptionSet: Set<string>; ldapSet: Set<string> } });

  // Sort combined roles by their arn string
  const sortedCombinedIamRoles = Object.values(combinedUniqueIamRoleArns).sort((iamRoleA, iamRoleB) =>
    iamRoleA.iamRoleArn > iamRoleB.iamRoleArn ? 1 : -1,
  );

  return sortedCombinedIamRoles.map(({ iamRoleArn, descriptionSet, ldapSet }) => ({
    label: iamRoleArn,
    value: iamRoleArn,
    // Join LDAP groups and description lists into a single string
    tags: ['LDAP:', ...Array.from(ldapSet).sort()],
    description: Array.from(descriptionSet).sort().join(' / '),
  }));
}

/**
 * A custom Content component specifically for managing IAM roles and chaining.
 */
export function ActivityIAMRoleContent(props: iActivityCredentialContent) {
  switch (props.mode) {
    case 'edit':
      // Edit returns an attribute editor which manages role definition and chaining
      return <EditIAMRoleContent />;
    case 'view':
      //View returns a simple table with a useful `type` column next to every role
      return <ViewActivityIAMRoleContent {...props} />;
    case 'loading':
      return <LabeledContentSkeleton label={label} />;
    default:
      return null;
  }
}

function EditIAMRoleContent() {
  const groupName = useGroupName();
  const ldapGroups = useLdapGroups();
  useConditionalEffect(() => {
    fetchIamRolesByGroup({ groupName }, true);
  }, [groupName]);

  const { data: iamRolesByGroup } = useIamRoles(groupName);
  // TODO: Eventually add refresh button adjacent to select to fetch the options again, and all cooldown to prevent spamming <Button iconName="refresh" variant="icon-inline" disabled={cooldown} loading={fetching} onClick={fetch...} />
  const iamRoleOptions = useMemo(() => iamRolesByGroupToOptions(iamRolesByGroup, ldapGroups), [iamRolesByGroup]);
  const { control, formState } = useFormContext<Activity>();

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: IAM_ROLES_PATH,
    rules: {
      //TODO: Eventually, should there be additional validations, e.g. ensuring all roles are unique?
      required: Rules.required(label),
      minLength: { value: 1, message: 'At least 1 role is required, please include an S3 IAM principal below.' },
      maxLength: {
        value: 10,
        message:
          'No more than 10 IAM roles may be defined. At most you may have 1 S3 role, 8 chain roles, and 1 Redshift cluster role)',
      },
    },
  });

  const definition: AttributeEditorProps<IAMRoleObj>['definition'] = [
    { label: 'Type', control: (item) => item.type },
    {
      label: 'Role',
      errorText: (_, index) => get(formState, `errors.${IAM_ROLES_PATH}[${index}].value.message`),
      control: (item, index) => {
        const subpath = `${IAM_ROLES_PATH}.${index}.value` as const;
        // Reconstructing the props used for content data-* attributes
        const props = { mode: DisplayMode.Edit, path: subpath, label: 'S3 Role', resourceType: 'activity' } as const;

        return (
          <Controller
            control={control}
            name={subpath}
            rules={{
              required: {
                value: true,
                message:
                  index === 0
                    ? `An S3 IAM role is required, please select one from the dropdown. `
                    : `All included roles must be defined. To remove chain role, click the ${SALTIRE} button.`,
              },
              pattern: Rules.pattern.iamRoleArn('Role'),
            }}
            render={({ field, fieldState }) => {
              return index === 0 ? (
                <span {...getContentDataAttributes('Select', props)}>
                  <Select
                    selectedOption={{ value: item.value, label: item.value }}
                    options={iamRoleOptions}
                    filteringType="auto"
                    ref={field.ref}
                    onBlur={field.onBlur}
                    invalid={fieldState.invalid}
                    onChange={({ detail }) => {
                      update(index, { value: detail.selectedOption.value });
                      // Calling field.onChange will trigger an evaluation of whether this field violates its defined validation rules (local state)
                      field.onChange(detail.selectedOption.value);
                    }}
                    placeholder={placeholder}
                  />
                </span>
              ) : (
                <span {...getContentDataAttributes('Text', props)}>
                  <Input
                    value={item.value}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    invalid={fieldState.invalid}
                    onChange={({ detail }) => {
                      update(index, { value: detail.value });
                      // Calling field.onChange will trigger an evaluation of whether this field violates its defined validation rules (local state)
                      field.onChange(detail.value);
                    }}
                    placeholder={placeholder}
                  />
                </span>
              );
            }}
          />
        );
      },
    },
  ];
  return (
    <FormField
      label={label}
      info={<HelpPanelInfoLink helpPanel="IamRoleChaining" />}
      errorText={get(formState, `errors.${IAM_ROLES_PATH}.root.message`)}
    >
      <span
        {...getContentDataAttributes('Text', {
          mode: DisplayMode.Edit,
          path: IAM_ROLES_PATH,
          label,
          resourceType: 'activity',
        })}
      >
        <AttributeEditor
          definition={definition}
          items={iamRolesToItems(fields)}
          onAddButtonClick={() => append({ value: '' })}
          onRemoveButtonClick={({ detail }) => remove(detail.itemIndex)}
          empty="No IAM roles defined"
          isItemRemovable={() => fields?.length > 1} // Do not remove the first role (IAM)
          disableAddButton={fields?.length >= 10} // Do not add more than 10 roles total (1 IAM, 1 Redshift, 8 Chain)
          removeButtonText={SALTIRE}
          addButtonText={`+ Add ${fields?.length === 1 ? 'Redshift' : 'chain'} role`}
        />
      </span>
    </FormField>
  );
}

function ViewActivityIAMRoleContent(props: iActivityCredentialContent) {
  const roles = useSelector((state: ReduxState) => get(state.data.activity.data[props.resourceId], IAM_ROLES_PATH));

  return (
    <Table
      data-testid="IAMRoleContentTable"
      sortingDisabled
      header={
        <Header variant="h3" info={<HelpPanelInfoLink helpPanel="IamRoleChaining" />}>
          {label}
        </Header>
      }
      items={iamRolesToItems(roles)}
      columnDefinitions={[
        { id: 'roleType', header: 'Type', cell: (item) => item.type },
        { id: 'roleValue', header: 'Role', cell: (item) => item.value },
      ]}
    />
  );
}

export function ActivityS3OdinContent(props: iActivityCredentialContent) {
  const groupName = useGroupName();
  useEffect(() => {
    if (groupName) {
      fetchMaterialSets({ groupName });
    }
  }, [groupName]);
  const { data: odinMaterialSets } = useOdinMaterialSets(groupName);

  return (
    <Content.Select
      resourceType="activity"
      resourceId={props.resourceId}
      mode={props.mode}
      path="config.S3_ACCESS_ODIN"
      infoHelpPanel="S3OdinMaterialSet"
      label="Odin material set for S3 source (deprecated)"
      options={valueListToOptions(odinMaterialSets)}
      filteringType="auto"
    />
  );
}

/** Renders either an IAM Role(s) Editor or an Odin selector, depending on the S3 Credential Type of the Pipeline  */
export function ActivityS3CredentialContent(props: iActivityCredentialContent) {
  const groupName = useGroupName();
  const { pipelineName } = useActivityInfoCache(props.resourceId)?.data ?? {};
  const s3CredentialType = useS3CredentialType({ groupName, pipelineName });

  if (s3CredentialType === keys.S3CredentialType.ODIN) {
    // NOTE: Odin is deprecated and only used for legacy pipelines
    // Legacy Odin pipelines can still be viewed & edited, but should NOT create new activities thru the UI
    return <ActivityS3OdinContent {...props} />;
  } else {
    // Default is IAM for all new pipelines:
    return <ActivityIAMRoleContent {...props} />;
  }
}
