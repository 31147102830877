import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  CollectionPreferences,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { msBetweenDates, durationToString, useAddActivityToRecentPages } from '../../commons';
import { compareBy } from 'src/commons/componentHelpers';
import { CustomStatusIndicator, EmptyState, RefreshButton, ToggleableDatetime } from '../helpers';
import PAGES from '../../nav/Pages';
import InstanceFromActivity from '../../interfaces/instanceInterfaces';
import { urlSearchString, useInternalOnFollow } from 'src/nav/navHelper';
import { useInstances, useActivityInfoCache, useGroupName } from 'src/data/redux';
import InstanceDateRangePicker, { dateRangeToStartAndEndDatetimes } from '../instance/InstanceDateRangePicker';
import InstanceStatusIdSelect from '../instance/InstanceStatusIdSelect';
import RerunExecutionInstanceButton from '../instance/RerunExecutionInstanceButton';
import { fetchInstancesByActivity } from 'src/data/api/fetchFromAPI';

// Due to a Polaris "quirk", all comparator functions must be declared OUTSIDE of a component in order to work
// Sage Post: https://refresh.sage.amazon.dev/posts/1371292#1371320
const startComparator = compareBy<InstanceFromActivity>('startDateTime', msBetweenDates);
const endComparator = compareBy<InstanceFromActivity>('endDateTime', msBetweenDates);
const scheduleComparator = compareBy<InstanceFromActivity>('scheduleDateTime', msBetweenDates);

/**
 * @returns A table with built-in filtering which shows the Instances corresponding to a particular Activity
 */
export default function MonitorActivity(props: { activityId: string }) {
  const onFollow = useInternalOnFollow();
  const { activityId } = useParams();
  const { activityName } = useActivityInfoCache(activityId)?.data ?? {};

  //Subscribe to store
  const { data, fetching } = useInstances(props.activityId);
  const instances = data || [];

  const groupName = useGroupName();

  // Subscribes to the start & end times (set by `<InstanceDateRangePicker>`) and the statusId (set by `<InstancesStatusIdSelect>`), both found in `<MonitorActivity>`
  const [searchParams] = useSearchParams();
  const dateRange = JSON.parse(searchParams.get('dateRange') || '{}');
  const instanceStatusId = parseInt(searchParams.get('instanceStatusId')) || -1;
  const [startScheduleDateTime, endScheduleDateTime] = dateRangeToStartAndEndDatetimes(dateRange);

  // Initialize filtering states
  const [preferences, setPreferences] = useState({ pageSize: 10 } as any);
  const [page, setPage] = useState(1);

  useAddActivityToRecentPages(activityId, activityName, groupName);

  const pagesCount = Math.ceil(instances.length / preferences.pageSize);

  // A 'useful' Polaris hook for defining table properties: https://polaris.a2z.com/get-started/dev-guides/collection-hooks/
  const { items, collectionProps, paginationProps, filterProps } = useCollection(
    [...instances].sort((a, b) => msBetweenDates(b.scheduleDateTime, a.scheduleDateTime)),
    {
      filtering: {
        empty: (
          <EmptyState
            title="No instances"
            subtitle="There were no instances found within the given dates for this activity."
          />
        ),
        noMatch: <EmptyState title="No matches" subtitle={`No instances matched the filtering string.`} />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    },
  );

  const FilteringTableHeader = (
    <SpaceBetween size="xs">
      <Header
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <RefreshButton
              refreshing={fetching}
              onRefresh={() => {
                fetchInstancesByActivity(
                  {
                    activityId: parseInt(activityId),
                    groupName,
                    startScheduleDateTime,
                    endScheduleDateTime,
                    instanceStatusId,
                  },
                  true,
                );
              }}
            />
          </SpaceBetween>
        }
        counter={`(${instances.length}/${instances.length})`}
        variant="h3"
      >
        Activity Instances
      </Header>
      <SpaceBetween size="s" direction="horizontal">
        <Box padding={{ top: 'xxs' }}>Fetch instances from </Box>
        <InstanceDateRangePicker />
        <Box padding={{ top: 'xxs' }}> with status </Box>
        <InstanceStatusIdSelect />
      </SpaceBetween>
    </SpaceBetween>
  );

  return (
    <SpaceBetween size="l">
      <Header variant="h2">Monitor Activity</Header>
      <Table
        {...collectionProps}
        data-testid="monitorActivityTable"
        loading={fetching}
        loadingText={`Loading instances for activity ${props.activityId}`}
        header={FilteringTableHeader}
        items={items}
        columnDefinitions={[
          {
            id: 'id',
            header: 'ID',
            cell: (item) => (
              <Link
                onFollow={onFollow}
                href={
                  PAGES.VIEW_INSTANCE.path
                    .replace(':activityId', props.activityId)
                    .replace(':instanceId', item.instanceId) + urlSearchString({ groupName })
                }
              >
                {item.instanceId}
              </Link>
            ),
            sortingField: 'instanceId',
          },
          {
            id: 'status',
            header: 'Status',
            cell: (item) => <CustomStatusIndicator>{item.status}</CustomStatusIndicator>,
            sortingField: 'status',
          },
          {
            id: 'retry',
            header: 'Retry',
            cell: (item) => (
              <RerunExecutionInstanceButton
                instanceId={item.instanceId}
                activityId={props.activityId}
                variant="inline-icon"
                onSuccess={() => {
                  fetchInstancesByActivity(
                    {
                      activityId: parseInt(activityId),
                      groupName,
                      startScheduleDateTime,
                      endScheduleDateTime,
                      instanceStatusId,
                    },
                    true,
                  );
                }}
              />
            ),
          },
          {
            id: 'schedule',
            header: 'Schedule',
            cell: (item) => <ToggleableDatetime>{item.scheduleDateTime}</ToggleableDatetime>,
            sortingComparator: startComparator,
          },
          {
            id: 'start',
            header: 'Start',
            cell: (item) => <ToggleableDatetime>{item.startDateTime}</ToggleableDatetime>,
            sortingComparator: endComparator,
          },
          {
            id: 'end',
            header: 'End',
            cell: (item) => <ToggleableDatetime>{item.endDateTime}</ToggleableDatetime>,
            sortingComparator: scheduleComparator,
          },
          {
            id: 'duration',
            header: 'Duration',
            cell: (item) => durationToString(item.duration, { granularity: 2 }),
            sortingField: 'duration',
          },
          {
            id: 'retries',
            header: 'Retry count',
            cell: (item) => item.retryCount,
            sortingField: 'retryCount',
          },
        ]}
        filter={
          <TextFilter
            data-testid="monitorActivityTable_textFilter"
            filteringAriaLabel="Filter instances"
            filteringPlaceholder="Filter instances by properties"
            {...filterProps}
          />
        }
        pagination={
          <Pagination
            data-testid="monitorActivityTable_pagination"
            currentPageIndex={page}
            pagesCount={pagesCount}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
            onChange={({ detail }) => {
              setPage(detail.currentPageIndex);
            }}
            {...paginationProps}
          />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            data-testid="monitorActivityTable_preferences"
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 10, label: '10 instances' },
                { value: 25, label: '25 instances' },
                { value: 50, label: '50 instances' },
              ],
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Select to see all the text within each cell by wrapping lines',
            }}
            preferences={preferences}
            onConfirm={({ detail }) => {
              setPreferences(detail);
              if (detail.pageSize !== preferences.pageSize) {
                setPage(1);
              }
            }}
          />
        }
      />
    </SpaceBetween>
  );
}
