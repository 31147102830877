import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSliceType } from '../../../interfaces/reduxInterfaces';

const initialState: UserSliceType = {
  // This initial state is just as an example
  //TODO: Programatically re-implement once APIs are developed
  auth: {
    alias: '',
    ldapGroups: [],
    hammerstoneGroups: undefined, // Set to undefined to manage loading state.
  },
  preferences: {
    codeEditor: { wrapLines: true, theme: 'dawn' },
    datetime: {
      isAbsolute: true,
    },
    announcementBanners: { dismissOldConsole: false, dismissEditActivityRisk: false, dismissAdblockAlert: false },
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser() {
      return initialState;
    },
    setAlias(state, { payload }: PayloadAction<string>) {
      state.auth.alias = payload;
    },
    setLdapGroups(state, { payload }: PayloadAction<string[]>) {
      state.auth.ldapGroups = payload;
    },
    setHammerstoneGroups(state, { payload }: PayloadAction<string[]>) {
      state.auth.hammerstoneGroups = payload;
    },
    updatePreferences<K extends keyof UserSliceType['preferences']>(
      state: UserSliceType,
      { payload }: PayloadAction<{ key: K; value: Partial<UserSliceType['preferences'][K]> }>,
    ) {
      state.preferences[payload.key] = { ...state.preferences[payload.key], ...payload.value };
    },
  },
});
