import { PutPipelineInfoRequest } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';

import { fetchPipelineInfo, fetchPipelineNames } from '../../data/api/fetchFromAPI';
import { PutPipelineInfo } from 'src/lib/hammerstoneApi';
import PAGES from '../../nav/Pages';
import { urlSearchString } from '../../nav/navHelper';
import PipelineContainerLayout from './PipelineContainerLayout';
import { HammerstoneAppLayout, tempFlashbarMessage } from '../helpers';
import { actions, useAlias, useGroupName, usePipeline } from 'src/data/redux';
import { delay, removeFromRecentPages, useAddPipelineToRecentPage, useConditionalEffect } from 'src/commons';
import Pipeline from 'src/interfaces/pipelineInterfaces';
import ReactHookFormWrapper from 'src/components/helpers/ReactHookFormWrapper';
import { DisplayMode } from '../helpers/content/contentInterfaces';

/** An ID used to de-duplicate flashbar messages for this particular action (e.g. warning, success, loading, error) */
const UPDATE_PIPELINE_FLASHBAR_ID = 'update_pipeline';

export default function EditPipeline(props: { pipelineName: string }) {
  const { pipelineName } = props;

  const groupName = useGroupName();
  const { data: pipeline, fetching } = usePipeline({ groupName, pipelineName });
  const alias = useAlias();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**  See the [How-To Guide](../helpers/content/HowToGuide.md) for usage examples of creating forms with `react-hook-form` and the custom [`<Content>`](../helpers/content/README.md) components  */
  const form = useForm<Pipeline>({ defaultValues: {}, mode: 'onTouched', reValidateMode: 'onChange' });
  useConditionalEffect(() => {
    // Update the defaultValues of the form asynchronously once the pipeline has loaded: https://react-hook-form.com/docs/useform/reset
    form.reset(pipeline);
  }, [pipeline]);

  useAddPipelineToRecentPage(pipelineName, groupName);

  useConditionalEffect(() => {
    fetchPipelineInfo(
      {
        pipelineName,
        groupName,
      },
      true,
    );
  }, [pipelineName, groupName]);

  const onCancel = () =>
    navigate({
      pathname: PAGES.VIEW_PIPELINE.path.replace(':pipelineName', pipelineName),
      search: urlSearchString({ groupName }),
    });

  const onSuccess = async (updatedPipelineName: string) => {
    // Flash a success message to the user
    tempFlashbarMessage({
      id: UPDATE_PIPELINE_FLASHBAR_ID,
      message: { header: `Successfully updated pipeline ${updatedPipelineName}` },
    });
    // Wait and then refresh pipeline info & pipelines list after update
    await delay(1000);
    fetchPipelineInfo(
      {
        groupName,
        pipelineName: updatedPipelineName,
      },
      true,
    );
    fetchPipelineNames({ groupName }, true);
    if (updatedPipelineName !== pipelineName) {
      // If the pipeline's name (which is its identifier) changes, removes outdated reference to old pipeline name on the Home page
      removeFromRecentPages('pipeline', pipelineName);
    }
    // Go to the view page for the succeeded pipeline
    navigate({
      pathname: PAGES.VIEW_PIPELINE.path.replace(':pipelineName', updatedPipelineName),
      search: urlSearchString({ groupName }),
    });
  };

  const onValidSubmit = async (pipeline: Pipeline) => {
    try {
      //Updates pipeline description and name
      const output: PutPipelineInfoRequest = {
        pipelineName, // Old pipeline name
        groupName,
        body: {
          operation: 'update',
          updatedBy: alias,
          // Updated pipeline description and name
          pipelineName: pipeline.pipelineName,
          description: pipeline.description,
        },
      };
      const { pipelineName: updatedPipelineName } = await PutPipelineInfo(output);
      onSuccess(updatedPipelineName);
    } catch (error) {
      console.error('Error while editing pipeline', error);
      dispatch(
        actions.page.addToFlashbar({
          id: UPDATE_PIPELINE_FLASHBAR_ID,
          message: {
            type: 'error',
            header: `Could not update pipeline ${pipelineName}=>`,
            content: error.toString(),
            dismissible: true,
          },
        }),
      );
    }
  };

  return (
    <HammerstoneAppLayout {...PAGES.EDIT_PIPELINE.pageLayoutProps({ pipelineName, groupName })}>
      <ReactHookFormWrapper form={form} header={pipelineName} onCancel={onCancel} onValidSubmit={onValidSubmit}>
        <PipelineContainerLayout pipelineName={pipelineName} mode={fetching ? DisplayMode.Loading : DisplayMode.Edit} />
      </ReactHookFormWrapper>
    </HammerstoneAppLayout>
  );
}
