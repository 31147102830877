import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';

/**
 * A popup modal which asks the user to confirm before commiting an action. This should be used for any high-cost and non-reversible actions, e.g. deleting a resource.
 *
 * ### Required Props
 * @param {boolean} visible Whether the modal is visible (usually a state of the parent)
 * @param {Function} setVisible A function to toggle the `visible` variable above (usually a setState function of the parent)
 * @param {Function} onConfirm A function to execute upon confirming an action (can be async)
 *
 * ### Optional Props
 * @param {ReactNode} header The header at the top of the modal. Defaults to "Confirm Action"
 * @param {ReactNode} children The body of the modal. Can include fields, forms, etc. Defaults to "Are you sure you want to commit this action?"
 * @param {string} confirmName The text in the primary button which commits the onConfirm action. Default is `Confirm`
 */
export function ConfirmActionModal(props: {
  visible: boolean;
  setVisible: Function;
  onConfirm: Function;
  loading?: boolean;
  disabled?: boolean;
  actions?: ReactNode;
  header?: ReactNode;
  children?: ReactNode;
  confirmName?: string;
}) {
  return (
    <Modal
      onDismiss={() => props.setVisible(false)}
      visible={props.visible}
      closeAriaLabel="Close action modal"
      footer={
        <Box float="right">
          <SpaceBetween size="xs" direction="horizontal">
            <Button variant="link" iconName="close" onClick={() => props.setVisible(false)}>
              Cancel
            </Button>
            {props.actions}
            <Button
              variant="primary"
              iconName="check"
              data-testid="confirm-modal-button"
              disabled={props.disabled}
              loading={props.loading}
              onClick={() => {
                if (props.visible) {
                  props.onConfirm();
                }
                props.setVisible(false);
              }}
            >
              {props.confirmName ?? 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={props.header ?? `Confirm Action`}
    >
      {props.children ?? 'Are you sure that you want to commit this action?'}
    </Modal>
  );
}
