import React, { useMemo } from 'react';
import keys from '../../../constants/hammerstoneConstantKeys';
import { ActivityPriority, MAX_BACKFILL_COUNT, ScheduleType } from '../../../constants/hammerstoneConstants';
import {
  couldBackfill,
  getBackfillCountThresholds,
  getBackfillsByType,
  hasUserEditedSchedule,
  objToOptions,
} from 'src/commons';
import Activity, { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import Content, { Rules } from 'src/components/helpers/content/';
import { Button, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useActivity } from 'src/data/redux';
import { useFormContext } from 'react-hook-form';
import { BackfillExecutionsAlert, NextExecutions } from 'src/components/helpers';

const { NEW, SCHEDULED, EDIT_PENDING } = keys.StatusCode;

/**
 * An editable container for information about an Activity's Schedule
 */
export default function ScheduleContainer(props: ActivityContainerProps) {
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  // Memoized at top level to avoid unnecessary computations
  const PriorityOptions = useMemo(() => objToOptions(ActivityPriority), []);
  const ScheduleTypeOptions = useMemo(() => objToOptions(ScheduleType), []);

  const { data: activity } = useActivity(props.activityId);
  const form = useFormContext<Activity>();
  // form.watch() tracks changes to field values: https://react-hook-form.com/docs/useform/watch
  const [scheduleDate, scheduleType, scheduleInterval] =
    form?.watch(['scheduleDate', 'scheduleType', 'scheduleInterval']) ?? [];

  const backfillProps = {
    ...props,
    viewValues: activity as any,
    editValues: { scheduleDate, scheduleType, scheduleInterval },
    djsLastExecutionDate: activity?.djsLastExecutionDate,
    activityStatus: activity?.activityStatusCode,
    isScheduleChanged: activity?.scheduleChanged,
  };
  const backfillsByType = getBackfillsByType(backfillProps);
  const { isBackfillExcessive } = getBackfillCountThresholds(backfillsByType?.EDIT_SCHEDULE?.count);

  return (
    <Container
      data-testid={`column-container:schedule`}
      header={<Header variant="h2">{props.header}</Header>}
      footer={
        <Content.Toggle {...contentProps} label="Retry on failure" path="activityRetryActive" expandable>
          <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="s" direction="horizontal">
              <Content.Number
                {...contentProps}
                label="Maximum retries"
                path="activityRetryMaximum"
                disableOn={{ paths: ['activityRetryActive'], condition: (activity) => !activity.activityRetryActive }}
                rules={(l) => ({
                  required: Rules.required(l),
                  min: Rules.min(0, l),
                  max: Rules.max(99, l),
                  validate: { int: Rules.isInteger(l) },
                })}
              />
              <Content.Number
                {...contentProps}
                label="Delay between retries (mins)"
                path="activityRetryDelay"
                disableOn={{ paths: ['activityRetryActive'], condition: (activity) => !activity.activityRetryActive }}
                rules={(l) => ({
                  required: Rules.required(l),
                  min: Rules.min(0, l),
                  max: Rules.max(60, l),
                  validate: { int: Rules.isInteger(l) },
                })}
              />
            </SpaceBetween>
            <SpaceBetween size="m">
              <Content.Toggle
                {...contentProps}
                label="Pause activity if all retries fail"
                path="activityRetryPause"
                disableOn={{ paths: ['activityRetryActive'], condition: (activity) => !activity.activityRetryActive }}
              />
            </SpaceBetween>
          </ColumnLayout>
        </Content.Toggle>
      }
    >
      <SpaceBetween size="m">
        <BackfillExecutionsAlert {...backfillProps} />
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="m">
            <Content.Datetime
              {...contentProps}
              label="Schedule date"
              path="scheduleDate"
              editDescription={
                <>
                  All datetimes are displayed in UTC. <i>Note: The scheduler ignores seconds.</i>
                  {props.editAction === 'edit' && hasUserEditedSchedule(backfillProps) && (
                    <>
                      To revert to the current schedule (undo changes),{' '}
                      <Button
                        variant="inline-link"
                        data-testid="resetSchedule"
                        disabled={!activity}
                        onClick={(e) => {
                          e.preventDefault();
                          (['scheduleDate', 'scheduleInterval', 'scheduleType'] as const).forEach((f) =>
                            form.resetField(f),
                          );
                          form.trigger();
                        }}
                      >
                        click here.
                      </Button>
                    </>
                  )}
                </>
              }
              rules={(l) => ({
                required: Rules.required(l),
                pattern: Rules.pattern.completeDatetime(l),
                validate: {
                  backfillCount: () =>
                    !couldBackfill(backfillProps) ||
                    !isBackfillExcessive ||
                    `Cannot backfill more than ${MAX_BACKFILL_COUNT} executions, please update the schedule date.`,
                },
              })}
              nowButton={backfillsByType?.EDIT_SCHEDULE?.scheduleUnit || 'minute'}
            />
            <NextExecutions {...backfillProps} />
          </SpaceBetween>
          <SpaceBetween size="m">
            <SpaceBetween size="s" direction="horizontal">
              <Content.Number
                {...contentProps}
                label="Every"
                path="scheduleInterval"
                rules={(l) => ({
                  required: Rules.required(l),
                  min: Rules.min(1, l),
                  max: Rules.max(999, l),
                  validate: { int: Rules.isInteger(l) },
                })}
                disableOn={{
                  paths: ['scheduleType'],
                  condition: (activity) => activity.scheduleType === keys.ScheduleType.ONETIME,
                }}
              />
              <Content.Select
                {...contentProps}
                label="Interval"
                path="scheduleType"
                options={ScheduleTypeOptions}
                rules={(l) => ({ required: Rules.required(l) })}
                onChange={(event, setField, setValue) => {
                  // Get value from event emitted by Select
                  const { value } = event.detail.selectedOption;
                  // Set the value of the current field to the selected option
                  setField(value);
                  if (value === keys.ScheduleType.ONETIME) {
                    // If setting the schedule type to only run once, overwrite the scheduleInterval to 1
                    setValue('scheduleInterval', 1);
                  }
                }}
              />
            </SpaceBetween>
          </SpaceBetween>
        </ColumnLayout>
        <Content.Select
          {...contentProps}
          label="Priority"
          path="activityPriority"
          options={PriorityOptions}
          rules={(l) => ({ required: Rules.required(l) })}
          disableOn={{
            paths: ['activityStatusCode'],
            condition: (activity) =>
              // Priority should NOT be disabled when creating an Activity, in which case the the activityStatus will not be defined
              activity?.activityStatusCode && ![NEW, SCHEDULED, EDIT_PENDING].includes(activity?.activityStatusCode)
          }}
        />
      </SpaceBetween>
    </Container>
  );
}
