import React, { useEffect } from 'react';
//TODO: Add dark mode https://polaris.a2z.com/get-started/dev-guides/dark-mode-implementation/
import '@amzn/awsui-global-styles/polaris.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import 'prismjs/themes/prism.css';
import { initializeUserGroups } from '../lib/auth';
import { PageRouter } from 'src/nav/Pages';
import { AwsRum } from 'aws-rum-web';
import { getAwsRumConfig, getServiceDomain, getServiceRegion } from 'src/constants/config';
import { clearOldRecentPagesCache } from 'src/commons';

const setupAWSRUM = () => {
  try {
    const config = getAwsRumConfig();

    const awsRum: AwsRum = new AwsRum(
      config.applicationId,
      config.applicationVersion,
      config.applicationRegion,
      config.awsRumConfig,
    );
    return awsRum;
  } catch (error) {
    console.error(error);
    // Ignore errors thrown during CloudWatch RUM web client initialization
    return undefined;
  }
};

export default function App() {
  // Executes all hooks at the top-level of the component (not nested) to avoid errors
  useEffect(() => {
    // Execute the following initializations when the App first mounts
    clearOldRecentPagesCache();
    if (getServiceDomain() !== 'devDesktop' && getServiceRegion() === 'us-east-1') {
      setupAWSRUM();
    }
    initializeUserGroups();
  }, []);

  return <PageRouter />;
}
