import React, { useState } from 'react';
import store, { actions, useActivity, useAlias, useGroupName } from '../../data/redux';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { fetchActivityInfo } from '../../data/api/fetchFromAPI';
import keys from '../../constants/hammerstoneConstantKeys';
import { PutActivityInfo } from 'src/lib/hammerstoneApi';
import {
  BackfillExecutionsAlert,
  ConfirmActionModal,
  CustomSVG,
  LinkButton,
  NextExecutions,
  tempFlashbarMessage,
} from '../helpers';
import { useForm, FormProvider } from 'react-hook-form';
import {
  useConditionalEffect,
  calculateBackfill,
  BackfillProps,
  getBackfillCountThresholds,
  ScheduleTypeToUnit,
} from 'src/commons';
import { MAX_BACKFILL_COUNT } from 'src/constants/hammerstoneConstants';
import PAGES from 'src/nav/Pages';
import { urlSearchString } from 'src/nav/navHelper';
import Content, { Rules } from '../helpers/content';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import Activity from 'src/interfaces/activityInterfaces';
import { PutActivityInfoBody } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';

const { EDIT_PENDING, NEW, SCHEDULED, PAUSED, COMPLETED } = keys.StatusCode;
const { activate, pause, resume, update } = keys.ActivityActions;

async function putActivityStatusWithFlashbar(input: {
  operation: keyof typeof keys.Operation;
  activityId: string;
  activityName: string;
  groupName: string;
  alias: string;
  body?: Partial<PutActivityInfoBody>;
}) {
  const { operation, activityId, activityName, groupName, alias, body } = input;
  try {
    await PutActivityInfo({
      groupName,
      activityId: parseInt(activityId),
      body: { ...body, operation, updatedBy: alias },
    });
    tempFlashbarMessage({
      id: `update_activity_status_success`,
      message: { header: `Successfully ${operation}d activity ${activityName} (${activityId})` },
    });
    fetchActivityInfo({ groupName, activityId: parseInt(activityId) }, true);
  } catch (error) {
    console.error(`Failed to ${operation} activity`, error);
    store.dispatch(
      actions.page.addToFlashbar({
        id: `${operation}_activity_failure`,
        message: {
          type: 'error',
          header: `Failed to ${operation} activity ${activityId}`,
          content: error.message,
          dismissible: true,
        },
      }),
    );
  }
}

export default function ActivityToggleStatusButton({ activityId }: { activityId: string }) {
  const { data: activity, fetching } = useActivity(activityId);
  const form = useForm<Activity>();
  useConditionalEffect(() => {
    form.reset(activity);
  }, [activity]);
  const editedScheduleDate = form.watch('scheduleDate');
  const fieldState = form.getFieldState('scheduleDate');
  const status = activity?.activityStatusCode;
  const alias = useAlias();
  const groupName = useGroupName();

  const [isModalVisible, setIsModalVisible] = useState(false);
  // State which tracks whether the button is waiting for an API response
  const [isCalling, setIsCalling] = useState(false);
  const setActivityStatus = async (operation: keyof typeof keys.Operation, body?: Partial<PutActivityInfoBody>) => {
    setIsCalling(true);
    await putActivityStatusWithFlashbar({
      operation,
      activityId,
      activityName: activity?.activityName,
      groupName,
      alias,
      body,
    });
    setIsCalling(false);
  };

  const disabled = !activity || !activityId || !alias || !groupName;
  const loading = fetching || isCalling;
  const buttonProps = { loading, disabled, 'data-testid': 'toggleActivityStatus' };

  const viewValues = {
    scheduleDate: activity?.scheduleDate,
    scheduleInterval: activity?.scheduleInterval,
    scheduleType: activity?.scheduleType,
  };
  const editValues = { ...viewValues, scheduleDate: editedScheduleDate };
  const backfill = calculateBackfill(viewValues);
  const newBackfill = calculateBackfill(editValues);
  const backfillProps: BackfillProps = {
    activityId,
    viewValues,
    editValues,
    mode: DisplayMode.Edit,
    djsLastExecutionDate: activity?.djsLastExecutionStatus,
    activityStatus: activity?.activityStatusCode,
    isScheduleChanged: activity?.scheduleChanged,
  };
  const currentThresholds = getBackfillCountThresholds(backfill.count);
  const newThresholds = getBackfillCountThresholds(newBackfill.count);
  const needsUpdate = currentThresholds.isBackfillWarning && fieldState.isDirty;

  if ([EDIT_PENDING, NEW, COMPLETED].includes(status as any)) {
    return (
      <>
        <Button {...buttonProps} onClick={() => setIsModalVisible(true)} iconSvg={CustomSVG.Play}>
          Activate activity
        </Button>
        <ConfirmActionModal
          header={`Activate activity ${activity?.activityName} (${activityId})`}
          actions={
            <LinkButton
              iconName="edit"
              href={PAGES.EDIT_ACTIVITY.path.replace(':activityId', activityId) + urlSearchString({ groupName })}
            >
              Edit activity
            </LinkButton>
          }
          loading={isCalling}
          onConfirm={async () => {
            form.trigger();
            form.handleSubmit(async ({ scheduleDate, comment }: Activity) => {
              if (needsUpdate) {
                await setActivityStatus(update, { scheduleDate });
              }
              await setActivityStatus(activate, { comment });
            })();
          }}
          confirmName={needsUpdate ? 'Update and activate' : 'Activate'}
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          disabled={newThresholds.isBackfillExcessive}
        >
          <SpaceBetween size="m">
            <BackfillExecutionsAlert {...backfillProps} />
            <FormProvider {...form}>
              <SpaceBetween size="m">
                <Content.Datetime
                  resourceType="activity"
                  resourceId={activityId}
                  mode={currentThresholds.isBackfill ? DisplayMode.Edit : DisplayMode.View}
                  label="Schedule date"
                  editDescription="To reduce backfills, edit your schedule date here before activating"
                  path="scheduleDate"
                  nowButton={ScheduleTypeToUnit[activity?.scheduleType]}
                  rules={(l) => ({
                    required: Rules.required(l),
                    pattern: Rules.pattern.completeDatetime(l),
                    validate: {
                      backfillCount: () =>
                        newBackfill.count < MAX_BACKFILL_COUNT ||
                        `Cannot backfill more than ${MAX_BACKFILL_COUNT} executions.`,
                    },
                  })}
                />
                <NextExecutions {...backfillProps} />
                <Content.Text
                  resourceType="activity"
                  resourceId={activityId}
                  mode={DisplayMode.Edit}
                  label="Revision comment"
                  path="comment"
                  placeholder="Revision comment (optional)"
                />
              </SpaceBetween>
            </FormProvider>
          </SpaceBetween>
        </ConfirmActionModal>
      </>
    );
  } else if (status === PAUSED) {
    return (
      <Button {...buttonProps} onClick={() => setActivityStatus(resume)} iconSvg={CustomSVG.Resume}>
        Resume activity
      </Button>
    );
  } else if (status === SCHEDULED) {
    return (
      <Button {...buttonProps} onClick={() => setActivityStatus(pause)} iconSvg={CustomSVG.Pause}>
        Pause activity
      </Button>
    );
  } else {
    // Show no button if activity status is not expected
    return <></>;
  }
}
