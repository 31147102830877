import React, { useEffect } from 'react';
import PAGES from '../../nav/Pages';
import { HammerstoneAppLayout } from '../helpers';
import ActivityContainerLayout from './ActivityContainerLayout';
import { useS3CredentialType } from 'src/data/redux';
import ReactHookFormWrapper from '../helpers/ReactHookFormWrapper';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import { useActivityForm } from './useActivityForm';
import { ActivityFormActions } from 'src/constants/hammerstoneConstants';
import keys from 'src/constants/hammerstoneConstantKeys';

const editAction = keys.ActivityFormActions.create;

export default function CreateActivity() {
  const { form, onCancel, onValidSubmit, groupName, ActivateButton, setSearchParams } = useActivityForm(
    editAction,
    undefined,
  );

  const [pipelineName, activityType] = form.watch(['activityNamespace', 'activityType']);
  const s3CredentialType = useS3CredentialType({ pipelineName, groupName });

  useEffect(() => {
    // Updates the Search Params with the new pipelineName whenever it is updated
    if (pipelineName) {
      setSearchParams((s) => {
        s.set('pipelineName', pipelineName);
        // Removes unnecessary & distracting token from URL bar (Midway)
        s.delete('id_token');
        return s;
      });
    }
  }, [pipelineName]);

  return (
    <HammerstoneAppLayout {...PAGES.CREATE_ACTIVITY.pageLayoutProps({ groupName, pipelineName })}>
      <ReactHookFormWrapper
        form={form}
        header={`Create activity`}
        onCancel={onCancel}
        onValidSubmit={onValidSubmit}
        // Do not allow submission if the user attempts to create a new activity in an Odin pipeline
        disabled={s3CredentialType === 'ODIN'}
        actions={ActivateButton}
        submitName={ActivityFormActions[editAction]}
      >
        <ActivityContainerLayout
          editAction={editAction}
          mode={DisplayMode.Edit}
          activityId={''}
          activityType={activityType}
        />
      </ReactHookFormWrapper>
    </HammerstoneAppLayout>
  );
}
