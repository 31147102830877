import { DateLike } from 'src/interfaces/reactInterfaces';
import keys from 'src/constants/hammerstoneConstantKeys';
import { ScheduleTypeToUnit, UnitType, breakdownDate, ensureUTC, intervalsBetween, lastDateOfMonth } from './time';
import { ActivityContainerProps } from 'src/interfaces/activityInterfaces';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';
import { MAX_BACKFILL_COUNT, MIN_IS_BACKFILL_COUNT, WARNING_BACKFILL_COUNT } from 'src/constants/hammerstoneConstants';

/** The input type for the `calculateBackfill` function which defines a particular schedule. See [documentation below](./backfill.ts) */
export interface BackfillInput {
  scheduleType: keyof typeof keys.ScheduleType;
  scheduleDate: DateLike;
  scheduleInterval: number;
}

/** The object type returned by the `calculateBackfill` function. See [documentation below](./backfill.ts) */
export interface BackfillObj extends BackfillInput {
  count: number;
  scheduleUnit: UnitType;
  now: DateLike;
}

/** The input type required by `backfillContext` as well as the [`<BackfillExecutionsAlert>`](../components/helpers/Schedule.tsx) component. See [documentation below](./backfill.ts)  */
export interface BackfillProps extends Omit<ActivityContainerProps, 'header'> {
  viewValues: BackfillInput;
  editValues: BackfillInput;
  djsLastExecutionDate?: DateLike;
  activityStatus?: keyof typeof keys.StatusCode;
  isScheduleChanged?: boolean;
}

export enum BackfillType {
  VIEW_SCHEDULE = 'VIEW_SCHEDULE',
  EDIT_SCHEDULE = 'EDIT_SCHEDULE',
  LAST_EXECUTION = 'LAST_EXECUTION',
}

export type BackfillsByType = { [key in BackfillType]: BackfillObj };

/**
 * @param input The schedule configuration for a given activity, consisting of a date interval and type
 * @returns A backfill object which returns the input as well as the potential backfill count, the scheduleUnit, and a date representing now
 */
export function calculateBackfill(input: BackfillInput): BackfillObj {
  let { scheduleDate, scheduleInterval, scheduleType } = input ?? {};
  const now = new Date();

  const scheduleUnit = ScheduleTypeToUnit[scheduleType];
  const invalidParams = !scheduleDate || !scheduleType || !scheduleUnit || !scheduleInterval || scheduleInterval < 1;
  const noBackfill = invalidParams || new Date(ensureUTC(scheduleDate)) > now;

  // Backfill is the number of intervals, plus 1 (includes the scheduleDate itself)
  // unless the scheduleDate is ahead of now, in which case there are no backfills
  // NOTE / TODO: The count logic is not fully accurate to account for daylight saving times or end-of-month edge cases but it account for the vast majority of cases.
  const count = noBackfill ? 0 : 1 + intervalsBetween(scheduleDate, now, scheduleUnit, scheduleInterval);

  return { ...input, count, scheduleUnit, now };
}

/** Gets backfill objects for the existing view schedule, the (potentially) edited schedule, and the (theoretical) schedule if resuming from the last execution */
export function getBackfillsByType(
  props: Pick<BackfillProps, 'viewValues' | 'editValues' | 'djsLastExecutionDate'>,
): BackfillsByType {
  const { viewValues, editValues, djsLastExecutionDate } = props ?? {};
  return {
    VIEW_SCHEDULE: calculateBackfill(viewValues),
    EDIT_SCHEDULE: calculateBackfill(editValues),
    LAST_EXECUTION: calculateBackfill({ ...viewValues, scheduleDate: djsLastExecutionDate }),
  };
}

/** Returns the type of backfill to expect if the current activity configuration were activated, or null for no backfill */
export function couldBackfill(props: BackfillProps): BackfillType {
  switch (props?.mode) {
    case DisplayMode.View:
      return couldBackfill_View(props);
    case DisplayMode.Edit:
      return couldBackfill_Edit(props);
    default:
      return null;
  }
}

/** Helper function for `couldBackfill` to keep logic clearer, calls helper function below in some cases */
function couldBackfill_Edit(props: BackfillProps): BackfillType {
  switch (props?.editAction) {
    case 'edit':
      if (hasUserEditedSchedule(props)) {
        // If the user has edited the schedule, the new schedule overrides the existing one
        return BackfillType.EDIT_SCHEDULE;
      } else {
        return couldBackfill_View(props);
      }
    default:
      return BackfillType.EDIT_SCHEDULE;
  }
}

/** Helper function for `couldBackfill` to keep logic clearer, called by `couldBackfill_Edit` in some cases */
function couldBackfill_View(props: Pick<BackfillProps, 'activityStatus' | 'isScheduleChanged'>): BackfillType {
  switch (props?.activityStatus) {
    case keys.StatusCode.NEW:
      return BackfillType.VIEW_SCHEDULE;
    case keys.StatusCode.EDIT_PENDING:
      return props.isScheduleChanged ? BackfillType.VIEW_SCHEDULE : null;
    case keys.StatusCode.PAUSED:
      return BackfillType.LAST_EXECUTION;
    default:
      return null;
  }
}

/** Gets the INFO, WARNING, and ERROR / EXCESSIVE thresholds for a particular backfill count */
export function getBackfillCountThresholds(count: number) {
  return {
    isBackfill: count > MIN_IS_BACKFILL_COUNT,
    isBackfillWarning: count > WARNING_BACKFILL_COUNT,
    isBackfillExcessive: count > MAX_BACKFILL_COUNT,
  };
}

/** Propose the last execution date if it would reduce the backfill count to below the warning threshold */
export function shouldProposeLastExecutionDate(backfillsByType: BackfillsByType) {
  return (
    backfillsByType?.LAST_EXECUTION?.scheduleDate &&
    backfillsByType?.VIEW_SCHEDULE?.count > WARNING_BACKFILL_COUNT &&
    backfillsByType?.LAST_EXECUTION?.count < WARNING_BACKFILL_COUNT
  );
}

/** Whether or not the user has edited the schedule from the EditActivity page. Note the lack of type checks as the form converts numbers to strings */
export function hasUserEditedSchedule(props: Pick<BackfillProps, 'viewValues' | 'editValues'>) {
  const { viewValues, editValues } = props;
  return (
    viewValues?.scheduleDate != editValues?.scheduleDate ||
    viewValues?.scheduleType != editValues?.scheduleType ||
    viewValues?.scheduleInterval != editValues?.scheduleInterval
  );
}

export interface NextExecutionInput extends BackfillInput {
  lastExecutionDate?: DateLike;
}
const { HOURS, DAYS, WEEKS, MONTHS } = keys.ScheduleType;

/** Get the subsequent execution schedule date for the given configuration. If no last execution date is provided, it defaults to the given schedule date */
export function getNextExecution(input: NextExecutionInput) {
  const { scheduleDate, scheduleInterval, scheduleType } = input ?? {};
  const _scheduleDate = breakdownDate(scheduleDate);
  const { date, hour, day, month } = breakdownDate(input.lastExecutionDate || scheduleDate);

  if (scheduleInterval > 0) {
    switch (scheduleType) {
      case HOURS:
        date.setUTCHours(hour + scheduleInterval);
        return date;
      case DAYS:
        date.setUTCDate(day + scheduleInterval);
        return date;
      case WEEKS:
        date.setUTCDate(day + scheduleInterval * 7);
        return date;
      case MONTHS:
        // UTC month is 0 indexed, subtract one. Initially set day to beginning of month (otherwise the month could accidentally be incremented)
        date.setUTCMonth(month + scheduleInterval - 1, 1);
        // If the schedule date is near the end of the month, we schedule the job back from the end of the month:
        // https://code.amazon.com/packages/AWSDWHammerstoneRepositoryService/blobs/c2d8f9437fbdad2619bff9bfd37787feab3ba3a3/--/src/com/amazon/awsdwhammerstonerepository/workflowservice/scheduler/DjsObjectFactory.java#L265-L268
        if (_scheduleDate.day >= Math.min(29, _scheduleDate.lastDateOfMonth)) {
          const daysFromEndOfMonth = _scheduleDate.lastDateOfMonth - _scheduleDate.day;
          date.setUTCDate(lastDateOfMonth(date) - daysFromEndOfMonth);
        } else {
          date.setUTCDate(day);
        }
        return date;
      default:
        return null;
    }
  } else if (scheduleInterval == 0) {
    return date;
  } else {
    return null;
  }
}

/** Returns the n next executions for the given schedule configuration, starting with the current scheduleDate */
export function getNextExecutions(input: NextExecutionInput, n: number): Date[] {
  const nextExecution = getNextExecution(input);
  if (!n || n < 0 || !nextExecution) {
    return null;
  } else {
    // Instead of recursively getting the next execution based on the previous one,
    // we multiply the interval by the desired number of executions (logically equivalent)
    return Array.from({ length: n }, (_, i) =>
      getNextExecution({ ...input, scheduleInterval: input.scheduleInterval * i }),
    );
  }
}
