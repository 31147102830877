import { Button, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import {
  FieldErrors,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn,
  useFormState,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ConfirmActionModal } from './ConfirmActionModal';
import { actions, useFormFieldErrors } from 'src/data/redux';
import { FormErrorFieldsText, fieldErrorsToPathMessageMap } from './ReactHookFormWrapperHelpers';

interface iFormActionButtons<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues, any, any>;
  onCancel: () => void;
  onValidSubmit: SubmitHandler<TFieldValues>;
  onInvalidSubmit?: SubmitErrorHandler<TFieldValues>;
  actions?: React.ReactNode;
  submitName?: React.ReactNode;
  disabled?: boolean;
}

/**
 * For usage and examples on how to create a Form component, see the [How-To Guide](./content/HowToGuide.md)
 *
 * @param props ReactHookFormWrapper requires the following props: `form`, `onCancel` and `onValidSubmit`
 *   - `form` A `react-hook-form` form object (Read more [here](https://react-hook-form.com/docs/useform))
 *   - `onCancel` An anonymous function which will be executed upon the user cancelling the form. A common pattern would be to `navigate` to the previous, or some relevant prior page.
 *     This component handles resetting the form for you. (Read more [here](https://react-hook-form.com/docs/useform/reset))
 *   - `onValidSubmit` A function to handle a valid form being submitted, passed into `form.handleSubmit` (Read more [here](https://react-hook-form.com/docs/useform/handlesubmit))
 *   - `children` Optional - The contents of the actual form. This is where you render various fields
 *   - `header` Optional - A header placed at the top of the form, which will also contain the relevant form actions
 *   - `onInvalidSubmit` Optional - A function to execute in case the user attempts to submit an invalid form. (See link above)
 *   - `actions` Optional - A ReactNode of other buttons/actions to render between the Submit and Cancel buttons.
 *
 *  @returns A Cloudscape/Polaris and `react-hook-form` Form in which the user can manage inputs and submit to the API.
 */
export default function ReactHookFormWrapper<TFieldValues extends FieldValues>(
  props: {
    children?: React.ReactNode;
    header?: React.ReactNode;
  } & iFormActionButtons<TFieldValues>,
) {
  const formActions = <FormActionButtons {...props} />;
  const formFieldErrors = useFormFieldErrors();

  return (
    // The FormProvider allows its children to retrieve the react-hook-form `form` object without manually passing it down thru props, read more here: https://react-hook-form.com/docs/formprovider and here: https://react-hook-form.com/docs/useformcontext
    <FormProvider {...props.form}>
      <form onSubmit={(e) => e.preventDefault()} noValidate>
        {/* Read more about Polaris forms here: https://cloudscape.aws.dev/components/form/ */}
        <Form
          header={
            props.header && (
              // added redundant `actions` to the header of the form so that user doesn't have to scroll all the way down to submit/cancel
              <Header variant="h1" actions={formActions}>
                {props.header}
              </Header>
            )
          }
          actions={formActions}
          errorText={formFieldErrors && Object.keys(formFieldErrors).length && <FormErrorFieldsText />}
        >
          <SpaceBetween size="xl">{props.children}</SpaceBetween>
        </Form>
      </form>
    </FormProvider>
  );
}

/**
 * This component renders the actions that a user can take on a form. By default, it will add a `Submit` and `Cancel` button,
 * but the developer can pass in further actions to be placed between them.
 *
 * Cloudscape Form Usage Guidelines: https://cloudscape.aws.dev/components/form/?tabId=usage
 *
 * See the `<ReactHookFormWrapper>` components above for details on the props
 * @returns The Action buttons to be displayed in the Header and at the bottom of a Form
 */
function FormActionButtons<TFieldValues extends FieldValues>(props: iFormActionButtons<TFieldValues>) {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  // Subscribe to changes in the form state to display whether the form is submitting (loading Submit button) or dirty (cancel requires approval)
  // Read more here: https://react-hook-form.com/docs/useform/formstate and https://react-hook-form.com/docs/useformstate
  const { isDirty, isSubmitting, isValid } = useFormState({});

  useEffect(() => {
    if (isValid) {
      // Once all the form returns to a valid state, remove the formFieldErrors flashbar
      dispatch(actions.page.setFormFieldErrors(null));
    }
    return () => {
      // Clear the form field errors upon exiting a page
      dispatch(actions.page.setFormFieldErrors(null));
    };
  }, [isValid]);

  /** The function to be executed upon Successfully cancelling a form */
  function cancelForm() {
    props.form.reset();
    props.onCancel();
  }

  const onValidSubmit = async (fields: TFieldValues, event?: React.BaseSyntheticEvent) => {
    dispatch(actions.page.setFormFieldErrors(null));
    await props.onValidSubmit(fields, event);
  };

  const onInvalidSubmit = async (errors: FieldErrors<TFieldValues>, event?: React.BaseSyntheticEvent) => {
    dispatch(actions.page.setFormFieldErrors(fieldErrorsToPathMessageMap(errors)));
    if (props.onInvalidSubmit) {
      await props.onInvalidSubmit(errors, event);
    } else {
      console.warn('Invalid submit', errors);
    }
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button
        data-testid="reactHookFormCancelButton"
        variant="link"
        iconName="close"
        onClick={(e) => {
          e.preventDefault();
          if (isDirty) {
            // If the form isDirty (its values have been changes by the user from the defaultValues), then popup a modal confirming the request
            setModalVisible(true);
          } else {
            // If the form is clean, simply reset it and cancel
            cancelForm();
          }
        }}
      >
        Cancel
      </Button>
      {props.actions}
      <Button
        data-testid="reactHookFormSubmitButton"
        variant="primary"
        onClick={(e) => {
          e.preventDefault();
          props.form.trigger();
          props.form.handleSubmit(onValidSubmit, onInvalidSubmit)();
        }}
        loading={isSubmitting}
        disabled={props.disabled}
      >
        {props.submitName ?? 'Submit'}
      </Button>
      {/* This Modal component will pop up and ask the user to confirm before Cancelling a form that has been edited from its original value, following Polaris / Cloudscape guidelines:
      Guidelines: https://cloudscape.aws.dev/patterns/general/unsaved-changes/

      TODO: Eventually generalize logic to catch other methods of exiting/cancelling the form page (e.g. https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd) */}
      <ConfirmActionModal
        visible={modalVisible}
        setVisible={setModalVisible}
        onConfirm={() => cancelForm()}
        confirmName="Reset"
        header="Would you like to reset this form?"
      >
        It looks like you have made changes to this form. If you would like to keep those changes, you may click the
        "Cancel" button and stay on this page. If you would like to reset this form, please confirm below.
      </ConfirmActionModal>
    </SpaceBetween>
  );
}
