import { Badge, Box, Button, ColumnLayout, Container, Icon, Link } from '@amzn/awsui-components-react';
import * as urls from 'src/constants/staticUrls';
import React, { ReactNode, useState } from 'react';
import { convertTime, msBetweenDates } from 'src/commons';
import * as CustomSVG from 'src/components/helpers/CustomSVG';

interface FeatureProps {
  header: string;
  children: ReactNode;
  releaseDate: Date;
}
const now = new Date();

const sortByReleaseDate = (propsA: FeatureProps, propsB: FeatureProps) =>
  msBetweenDates(propsA.releaseDate, propsB.releaseDate);

/** List of feature highlights. Whenever a new feature is added, include a short announcement to users here!  */
const FeaturePropsList: FeatureProps[] = [
  {
    header: 'Advanced activity schedule insights',
    children:
      'Understand and manage your job scheduling more easily with information about the next execution schedule dates, estimated backfills, and easy actions for keeping your schedule up-to-date. \
      Find more details in the Schedule section of the View and Edit Activity pages.',
    releaseDate: new Date('2024-04-01'),
  },
  {
    header: 'One-click actions for Pipeline, Activities, and Instances',
    children: (
      <>
        You can now perform the following on your Hammerstone resources with just a single click!
        <br />
        <br /> &nbsp;&nbsp;&nbsp;&nbsp; <Icon svg={CustomSVG.Play} size="small" /> &nbsp;&nbsp; Activate
        <br /> &nbsp;&nbsp;&nbsp;&nbsp; <Icon svg={CustomSVG.Pause} size="small" /> &nbsp;&nbsp; Pause
        <br /> &nbsp;&nbsp;&nbsp;&nbsp; <Icon svg={CustomSVG.Resume} size="small" /> &nbsp;&nbsp; Resume
        <br /> &nbsp;&nbsp;&nbsp;&nbsp; <Icon svg={CustomSVG.Rerun} size="small" /> &nbsp;&nbsp; Rerun
      </>
    ),
    releaseDate: new Date('2023-12-20'),
  },
  {
    header: 'Dropdowns for CTI, IAM, and Cluster',
    children:
      'Instead of manually inputting values, users can now quickly select their Redshift cluster, IAM role, and CTI info when modifying activities. \
      Large dropdowns (e.g. Category) will now use dynamic filtering for a smoother experience!',
    releaseDate: new Date('2023-05-4'),
  },
  {
    header: 'Lightweight console',
    children:
      'Create, monitor, and manage your Extract, Transform, and Load jobs with a few simple clicks on the new, faster Hammerstone console.',
    releaseDate: new Date('2023-04-01'),
  },
  {
    header: 'Programmatic access',
    children: (
      <>
        Take advantage of programmatic access through a new{' '}
        <Link href={urls.HAMMERSTONE_API_USER_GUIDE_WIKI} external>
          API library
        </Link>{' '}
        to automate your Hammerstone resources through TypeScript, Python, or other AWS SDK clients.
      </>
    ),
    releaseDate: new Date('2023-03-30'),
  },
  {
    header: 'Alarming and notifications',
    children: (
      <>
        Get notifications and alarms when a business critical job fails through the new{' '}
        <Link href={urls.SNS_NOTIFICATION_USER_GUIDE_WIKI} external>
          Hammerstone notification feature
        </Link>{' '}
        powered by Amazon SNS.
      </>
    ),
    releaseDate: new Date('2023-03-29'),
  },
  {
    header: 'Static and dynamic scheduling',
    children:
      'Automate your ETL activities using both a static schedule and a dynamic precondition on another ETL activity.',
    releaseDate: new Date('2023-03-28'),
  },
].sort(sortByReleaseDate);

const FEATURES_TO_DISPLAY = 4;
const HighlightedFeatures = FeaturePropsList.slice(0, FEATURES_TO_DISPLAY);
const RemainingFeatures = FeaturePropsList.slice(FEATURES_TO_DISPLAY);

function Feature(props: FeatureProps) {
  const daysSince = convertTime(msBetweenDates(props.releaseDate, now), 'millisecond', 'day');
  return (
    <div data-test-class="featureAnnouncement">
      <Box variant="h3" padding={{ top: 'n' }}>
        {/* Features added in the past 14 days will be preceded by a "New!" badge to draw users' attention */}
        {daysSince <= 14 && <Badge color="green">New!</Badge>} {props.header}
      </Box>
      <Box variant="p">{props.children}</Box>
    </div>
  );
}

/** A component which displays the most recent features to users  */
export function FeatureHighlights() {
  const [expanded, setExpanded] = useState(false);

  const ExpandButton = () => (
    <Box textAlign="center" padding={{ top: 'm' }}>
      <Button
        iconName={expanded ? 'angle-up' : 'angle-down'}
        onClick={() => setExpanded((x) => !x)}
        variant="link"
        data-testid="toggleFeatureExpansion"
      >
        see {expanded ? 'fewer' : 'more'} features
      </Button>
    </Box>
  );

  return (
    <div>
      <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
        Feature highlights
      </Box>
      <Container>
        <ColumnLayout columns={2} variant="text-grid">
          {React.Children.toArray(
            // Displays the most recent features to the user
            HighlightedFeatures.map(Feature),
          )}
        </ColumnLayout>
        {expanded && (
          <>
            <ColumnLayout columns={2} variant="text-grid">
              {React.Children.toArray(
                // Displays the most recent features to the user
                RemainingFeatures.map(Feature),
              )}
            </ColumnLayout>
          </>
        )}
        <ExpandButton />
      </Container>
    </div>
  );
}

export default FeatureHighlights;
