import React, { useEffect } from 'react';
import { BreadcrumbGroup, BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import * as breadcrumbItems from '../../nav/breadcrumbItems';
import { useInternalOnFollow } from '../../nav/navHelper';
import { fetchActivityInfo, fetchPipelineInfo } from 'src/data/api/fetchFromAPI';
import { useActivityInfoCache, useGroupName } from 'src/data/redux';

const getActivityCache = (activityId: string) => {
  const groupName = useGroupName();
  const { pipelineName, activityName } = useActivityInfoCache(activityId)?.data ?? {};

  useEffect(() => {
    if (activityId) {
      //Fetches activity for faster loading thru the breadcrumbs
      fetchActivityInfo({ groupName, activityId: parseInt(activityId) });
    }
  }, [groupName, activityId]);

  useEffect(() => {
    if (pipelineName) {
      //Fetches pipeline for faster loading thru the breadcrumbs
      fetchPipelineInfo({ pipelineName, groupName });
    }
  }, [groupName, pipelineName]);

  return { pipelineName, activityName };
};

/**
 * @param items A list of BreadcrumbGroup items
 * https://polaris.a2z.com/components/breadcrumb-group/?tabId=api
 * @param action - Optional A string describing the action of the current page (e.g. 'Create', 'Edit', etc.)
 * @returns A `<BreadcrumbGroup>` with the given items, using internal navigation for links
 */
export function BreadcrumbsFromItems(items: BreadcrumbGroupProps['items'], action?: string): JSX.Element {
  const onFollow = useInternalOnFollow();
  if (items.length) {
    if (action) {
      items = [...items, { text: action, href: '' }];
    }
    return <BreadcrumbGroup items={items} ariaLabel="Breadcrumbs" onFollow={onFollow} />;
  } else {
    return null;
  }
}

export function HomeBreadcrumbs(props: { action?: string }) {
  const groupName = useGroupName();
  return BreadcrumbsFromItems(breadcrumbItems.home(groupName), props.action);
}

export function ManagePipelinesBreadcrumbs(props: { action?: string }) {
  const groupName = useGroupName();
  return BreadcrumbsFromItems(breadcrumbItems.managePipelines(groupName), props.action);
}

export function PipelineBreadcrumbs(props: { pipelineName: string; action?: string }) {
  const groupName = useGroupName();
  return BreadcrumbsFromItems(breadcrumbItems.pipeline(groupName, props.pipelineName), props.action);
}

export function ActivityBreadcrumbs(props: { activityId: string; action?: string }) {
  const groupName = useGroupName();
  const { activityName, pipelineName } = getActivityCache(props.activityId);
  return BreadcrumbsFromItems(
    breadcrumbItems.activity(groupName, pipelineName, props.activityId, activityName),
    props.action,
  );
}

export function InstanceBreadcrumbs(props: { activityId: string; instanceId: string; action?: string }) {
  const groupName = useGroupName();
  const { activityName, pipelineName } = getActivityCache(props.activityId);
  return BreadcrumbsFromItems(
    breadcrumbItems.instance(groupName, pipelineName, props.activityId, activityName, props.instanceId),
    props.action,
  );
}
