import React from 'react';
import { Box, Popover, Button, StatusIndicator } from '@amzn/awsui-components-react';
import { getNodeText, copyToClipboard } from '../../commons';
import { DataAttributes, filterDataAttributes } from 'src/commons/dataAttributes';

interface CopySpanProps extends DataAttributes {
  children: React.ReactNode;
  toCopy?: string | (() => string);
  successPopover?: React.ReactNode;
}

/**
 * @param {ReactNode} children The text content to be copied (will parse text from nodes, or can just be string)
 * @param {string | Function} toCopy Optional: If defined, replaces the children's text content in the clipboard (e.g. to avoid formatting issues).
 * Can either be a string (which is directly copied) or a function which returns a string (to asynchronously generate a string)
 * @param {ReactNode} successPopover The content of the popover displayed upon a succesful copy. Default is a success status indicator reading "Copied!"
 * @returns The children, preceded by a clickable copy icon which will save the content to the clipboard.
 */
export function CopySpan({ children, toCopy, successPopover, ...props }: CopySpanProps) {
  //Designed according to Polaris best practices for copying: https://polaris.a2z.com/components/copy-to-clipboard/
  return (
    <span className="copy-wrapper custom-copy-span">
      <Box margin={{ right: 'xxs' }} display="inline-block">
        <Popover
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={successPopover ?? <StatusIndicator type="success"> Copied!</StatusIndicator>}
        >
          <Button
            variant="inline-icon"
            iconName="copy"
            iconAlt="Copy"
            onClick={() => {
              //Extracts the text from the children Nodes if toCopy is undefined or blank
              const childrenText: string = getNodeText(children);
              // If toCopy is a defined function, calls it. Otherwise returns (string or undefined)
              const textFromProps: string = typeof toCopy === 'function' ? toCopy() : toCopy;
              copyToClipboard(textFromProps ?? childrenText);
            }}
          />
        </Popover>
      </Box>
      <span style={{ wordWrap: 'break-word' }} className="custom-copy-span-child" {...filterDataAttributes(props)}>
        {children}
      </span>
    </span>
  );
}
