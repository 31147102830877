import {
  Alert,
  Badge,
  Box,
  Button,
  Header,
  Link,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  BackfillProps,
  BackfillType,
  couldBackfill,
  dateToApiString,
  getBackfillCountThresholds,
  getBackfillsByType,
  getNextExecutions,
  shouldProposeLastExecutionDate,
  timeRelativeToNow,
  truncateUTCDate,
  unitTypeToDescriptiveNoun,
} from 'src/commons';
import Activity from 'src/interfaces/activityInterfaces';
import { ToggleableDatetime } from './ToggleableDatetime';
import { DisplayMode } from './content/contentInterfaces';

/** Warn customers that their current job state and configuration will result in some number of backfills.
 * Has four distinct states, depending on thresholds defined in [hammerstoneConstants](../../constants/hammerstoneConstants.tsx):
 *   - Empty : there are no backfills for this job (count <= MIN_IS_BACKFILL_COUNT)
 *   - Info: Notifies customer of small backfill count (MIN_BACKFILL_COUNT < count <= WARNING_BACKFILL_COUNT)
 *   - Warning: Notifies customer of a large backfill count (WARNING_BACKFILL_COUNT < count <= MAX_BACKFILL_COUNT). Provides suggeestions for remediation.
 *   - Error: Notifies customer of an excessive backfill count (MAX_BACKFILL_COUNT < count). Urges remediation and will mark scheduleDate field as invalid
 *
 * For more information, see the documentation in the [backfill.ts helper](../../commons/backfill.ts) */
export function BackfillExecutionsAlert(props: BackfillProps) {
  const { viewValues, editValues } = props;
  const form = useFormContext<Activity>();

  const couldBackfillType = couldBackfill(props);

  if (couldBackfillType) {
    const backfillsByType = getBackfillsByType(props);
    const backfill = backfillsByType[couldBackfillType];
    const { isBackfill, isBackfillWarning, isBackfillExcessive } = getBackfillCountThresholds(backfill?.count);

    // Helper functional components to display quick actions available to user while editing a job that will backfill beyond the warning threshold
    const excessiveIntro = (
      <>
        You <b>must</b> move{/* the schedule date forward ... */}
      </>
    );
    const warningIntro = (
      <>
        We <b>strongly</b> recommend that you reduce backfills by moving{/* the schedule date forward ... */}
      </>
    );
    const nowLink = (
      <Link
        data-testid="setScheduleToNow"
        onFollow={(e) => {
          e.preventDefault();
          const now = new Date();
          const truncatedDate = truncateUTCDate(now, backfill.scheduleUnit);
          form.setValue('scheduleDate', dateToApiString(truncatedDate));
          form.trigger();
        }}
      >
        {unitTypeToDescriptiveNoun(backfill.scheduleUnit).toLowerCase()}
      </Link>
    );
    const lastExecutionAndNowLinks = (
      <>
        <Link
          data-testid="setScheduleToLastExecution"
          onFollow={(e) => {
            e.preventDefault();
            form.setValue('scheduleDate', dateToApiString(props.djsLastExecutionDate));
            form.trigger();
          }}
        >
          last execution
        </Link>{' '}
        or {nowLink}
      </>
    );

    const intro = isBackfillExcessive ? excessiveIntro : warningIntro;
    const quickActionLinks = shouldProposeLastExecutionDate(backfillsByType) ? lastExecutionAndNowLinks : nowLink;
    const BackfillQuickActions = () => (
      <Box>
        {intro} the schedule date forward, for example to the {quickActionLinks}.
      </Box>
    );

    if (isBackfill) {
      return (
        <Alert
          data-testid="backfillExecutionsAlert"
          header={
            <Header variant="h3" counter={`(${backfill.count.toLocaleString()})`}>
              Backfill executions
            </Header>
          }
          type={isBackfillExcessive ? 'error' : isBackfillWarning ? 'warning' : 'info'}
        >
          <Box>
            This schedule will attempt to backfill{' '}
            <Badge
              data-testid="backfillCount"
              color={isBackfillExcessive ? 'red' : isBackfillWarning ? 'blue' : 'grey'}
            >
              {backfill.count.toLocaleString()}
            </Badge>{' '}
            executions starting from{' '}
            <ToggleableDatetime noCopy>
              {props.mode === DisplayMode.View ? viewValues?.scheduleDate : editValues?.scheduleDate}
            </ToggleableDatetime>
            .
          </Box>
          {props.mode === DisplayMode.Edit && isBackfillWarning && <BackfillQuickActions />}
        </Alert>
      );
    }
  }
  return null;
}

// TODO: Eventually retrieve the currently-active activity's schedule to show diff between
// currently running schedule versus the currently configured (but not yet active) schedule

/** Display a Popover indicating the next scheduled execution. Upon clicking, the customer can see further executions. */
export function NextExecutions(props: BackfillProps) {
  const [showMore, setShowMore] = useState(false);
  const count = showMore ? 12 : 3;

  const backfillType = couldBackfill(props);
  const isNewSchedule = [BackfillType.VIEW_SCHEDULE, BackfillType.EDIT_SCHEDULE].includes(backfillType);
  const backfillsByType = getBackfillsByType(props);
  const backfill = backfillsByType[backfillType ?? BackfillType.VIEW_SCHEDULE];
  const nextExecutions = getNextExecutions(
    {
      ...backfill,
      lastExecutionDate: isNewSchedule ? null : props.djsLastExecutionDate,
    },
    // Get N + 1 executions and then remove either the last (new) or first (old) date
    count + 1,
  );

  if (!nextExecutions) {
    return null;
  }

  const startFrom = isNewSchedule ? 0 : 1;

  return (
    <SpaceBetween size="xxs" direction="horizontal">
      <Popover
        size="medium"
        header="Upcoming executions"
        triggerType="text"
        content={
          <SpaceBetween size="xxs">
            <Box>Your job is scheduled to next execute for the following dates:</Box>
            <ul>
              {React.Children.toArray(
                nextExecutions.slice(startFrom, startFrom + count).map((nextExecution) => (
                  <li>
                    <ToggleableDatetime noCopy>{nextExecution}</ToggleableDatetime>
                  </li>
                )),
              )}
            </ul>
            {!showMore && (
              <Button variant="inline-link" iconName="add-plus" onClick={() => setShowMore(true)}>
                Show more
              </Button>
            )}
            {nextExecutions[startFrom].getTime() < Date.now() && (
              <>
                <hr />
                <StatusIndicator type="info" colorOverride="grey">
                  The next execution is in the past because your job may be paused, delayed, or backfilling from the
                  configured schedule date.
                </StatusIndicator>
              </>
            )}
          </SpaceBetween>
        }
      >
        <i>{isNewSchedule ? 'First scheduled execution, once activated' : 'Next scheduled execution'}:</i>{' '}
        {timeRelativeToNow(nextExecutions[startFrom]).toLowerCase()}
      </Popover>
    </SpaceBetween>
  );
}
