import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';

import PAGES from '../../nav/Pages';
import { fetchActivityInfo, fetchPipelineInfo } from '../../data/api/fetchFromAPI';
import ActivityContainerLayout from './ActivityContainerLayout';
import { useActivity, useActivityInfoCache, usePipeline } from '../../data/redux';
import { _DefaultConfigMetadataKeys } from '../../interfaces/configMetadataInterfaces';
import { HammerstoneAppLayout } from '../helpers';
import { useAddActivityToRecentPages, useConditionalEffect } from 'src/commons';
import ReactHookFormWrapper from '../helpers/ReactHookFormWrapper';
import Content, { Rules } from '../helpers/content/';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import { AcknowledgeEditActivityRisk } from './AcknowledgeEditActivityRisk';
import { useActivityForm } from './useActivityForm';
import { ActivityFormActions } from 'src/constants/hammerstoneConstants';
import keys from 'src/constants/hammerstoneConstantKeys';

const editAction = keys.ActivityFormActions.edit;

export default function EditActivity({ activityId }: { activityId: string }) {
  const { form, onCancel, onValidSubmit, groupName, ActivateButton } = useActivityForm(editAction, activityId);

  const { data: activity } = useActivity(activityId);
  const { activityName } = useActivityInfoCache(activityId)?.data ?? {};
  const pipelineName = activity?.activityNamespace;
  useConditionalEffect(() => {
    // Asynchronously update the default form fields with the values from the existing activity once it loads, and change its name to avoid conflict
    // https://react-hook-form.com/docs/useform/reset
    form.reset(activity);
  }, [activity]);

  const { data: pipeline } = usePipeline({ pipelineName, groupName });
  useAddActivityToRecentPages(activityId, activityName, groupName);

  const otherActivityNames = pipeline
    ? pipeline.activityInfoList
        .filter((activityInfo) => activityInfo.activityId.toString() !== activityId)
        .map(({ activityName }) => activityName)
    : [];

  useConditionalEffect(() => {
    fetchPipelineInfo({ groupName, pipelineName });
  }, [pipelineName, groupName]);

  const { activityType } = activity || {};

  useConditionalEffect(() => {
    fetchActivityInfo({ groupName, activityId: parseInt(activityId) });
  }, [groupName, activityId]);

  // TODO: Eventualyl consider Activities that are CHILD_READ_ONLY (!)
  return (
    <HammerstoneAppLayout {...PAGES.EDIT_ACTIVITY.pageLayoutProps({ activityId })}>
      <AcknowledgeEditActivityRisk activityId={activityId} />
      <ReactHookFormWrapper
        form={form}
        header={`Edit activity ${activityId}`}
        onCancel={onCancel}
        onValidSubmit={onValidSubmit}
        actions={ActivateButton}
        submitName={ActivityFormActions[editAction]}
      >
        <SpaceBetween size="xl">
          <span className="header-content">
            <Content.Text
              mode={activityName ? DisplayMode.Edit : DisplayMode.Loading}
              resourceType="activity"
              resourceId={activityId}
              label="Activity name"
              path="activityName"
              placeholder="e.g. activity_name-123"
              rules={(l) => ({
                required: Rules.required(l),
                minLength: Rules.minLength(1, l),
                maxLength: Rules.maxLength(128, l),
                pattern: Rules.pattern.lettersNumbersHyphenUnderscore(l),
                validate: {
                  uniqueActivityName: (activityName) =>
                    !otherActivityNames.includes(activityName) ||
                    `An activity with name "${activityName}" already exists in pipeline "${pipelineName}". Please choose a unique activity name.`,
                },
              })}
            />
          </span>
          <ActivityContainerLayout
            editAction={editAction}
            mode={DisplayMode.Edit}
            activityId={activityId}
            activityType={activityType}
          />
        </SpaceBetween>
      </ReactHookFormWrapper>
    </HammerstoneAppLayout>
  );
}
