import {
  CreateActivityInfoBody,
  OnFailureAlarm,
  PutActivityInfoBody,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import configMetadataPostprocess from './configMetadataPostprocessor';
import keys from '../../constants/hammerstoneConstantKeys';
import Activity from 'src/interfaces/activityInterfaces';
import { completeDatetimeRe } from 'src/components/helpers/content/Rules';

function onFailureAlarmPostprocessor(activityForm: Activity): OnFailureAlarm {
  const formAlarm = activityForm.alarm;
  const onFailureAlarm: OnFailureAlarm = {
    active: formAlarm.active ? 'true' : 'false',
    // If any of the below fields are null, undefined, or empty ('') then the API will expect a `null` value instead of an empty string
    login: formAlarm.login || null,
    dedupe: formAlarm.dedupe || null,
    severity: formAlarm.severity || null,
    category: formAlarm.category || null,
    type: formAlarm.type || null,
    item: formAlarm.item || null,
  };
  // If any of the CTI fields are blank/empty, remove all the fields
  if ([onFailureAlarm.category, onFailureAlarm.type, onFailureAlarm.item].some((value) => !value)) {
    delete formAlarm.category;
    delete formAlarm.type;
    delete formAlarm.item;
  }

  return onFailureAlarm;
}

export const activityFormToUpdateBody = (activityForm: Activity, alias: string): PutActivityInfoBody => {
  const updateBody: Partial<PutActivityInfoBody> = {
    updatedBy: alias,
    operation: keys.ActivityActions.update,
  };

  activityDefaultPostprocessor(updateBody, activityForm);
  updateBody['comment'] = activityForm.comment;

  return updateBody as PutActivityInfoBody;
};

export function activityFormToCreateBody(activityForm: Activity, alias: string): CreateActivityInfoBody {
  const createBody: Partial<CreateActivityInfoBody> = {
    createdBy: alias,
    activityName: activityForm.activityName,
  };

  activityDefaultPostprocessor(createBody, activityForm);
  createBody.activityNamespace = activityForm.activityNamespace;

  // Only add ETL activity type if creating a new activity, since API disallows updates to activity type
  createBody.configurationMetadata.keyValuesList.push({ key: 'ETL_LOAD_TYPE', value: activityForm.activityType });

  return createBody as CreateActivityInfoBody;
}

/** Adds and postprocesses the default keys for both creating and editing an activity. Operation happens in-place on the body object, and also returns a pointer. */
function activityDefaultPostprocessor<BodyType extends PutActivityInfoBody | CreateActivityInfoBody>(
  body: Partial<BodyType>,
  activityForm: Activity,
): BodyType {
  // String keys
  body.activityName = activityForm.activityName;

  const [matched, time] = activityForm.scheduleDate.match(completeDatetimeRe) ?? [];
  // If the time is left blank, set it to a default of midnight UTC
  body.scheduleDate = time ? matched : `${matched} 00:00:00`;

  body.scheduleType = activityForm.scheduleType;
  body.clusterName = activityForm.clusterName;
  body.s3PreconRegion = activityForm.s3PreconRegion;

  // Boolean keys (explicitly converted to avoid undefined)
  body.activityRetryActive = activityForm.activityRetryActive ? true : false;
  body.activityRetryPause = activityForm.activityRetryPause ? true : false;

  // Number Keys, make sure they are all ints (no dec)
  // In case the schedule type is set to only run once, automatically set the schedule interval to 1.
  body.scheduleInterval =
    body.scheduleType === keys.ScheduleType.ONETIME ? 1 : Math.floor(activityForm.scheduleInterval);
  body.activityPriority = Math.floor(activityForm.activityPriority);
  body.scheduleDateOffset = Math.floor(activityForm.scheduleDateOffset);
  body.activityRetryDelay = Math.floor(activityForm.activityRetryDelay);
  body.activityRetryMaximum = Math.floor(activityForm.activityRetryMaximum);

  // Preconditions, mapping keys and values to prevent extraneous props from being added to the object (i.e. useFieldArray adds 'id')
  body.preconditions = activityForm.preconditions.map(({ key, value }) => ({ key, value }));

  body['configurationMetadata'] = configMetadataPostprocess(activityForm);

  // onFailureAlarm from alarm
  body.onFailureAlarm = onFailureAlarmPostprocessor(activityForm);
  return body as BodyType;
}
