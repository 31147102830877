import { Path, useWatch } from 'react-hook-form';
import { DataType, ReduxState, UserSliceType } from '../../interfaces/reduxInterfaces';
import { useSelector } from 'react-redux';
import { pipelineIdentifier } from 'src/interfaces/pipelineInterfaces';
import { useSearchParams } from 'react-router-dom';
import { getDefaultGroupName } from 'src/lib/auth';

// User Slice Selectors
export const useGroupName = () => {
  const [searchParams] = useSearchParams();
  const hammerstoneGroups = useSelector((state: ReduxState) => state.user.auth.hammerstoneGroups);
  const groupName = searchParams.get('groupName');
  // TODO: Remove following line and uncomment the subsequent return statement to remove default group logic
  return getDefaultGroupName(hammerstoneGroups, groupName);
  // return hammerstoneGroups?.includes(groupName) ? groupName : null;
};

export const useAuth = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useSelector((state: ReduxState) => state.user.auth);
  const groupName = searchParams.get('groupName');
  return {
    ...auth,
    groupName: auth.hammerstoneGroups?.includes(groupName) ? groupName : null,
    setGroupName: (groupName: string) =>
      setSearchParams((params) => {
        if (!groupName) {
          // Null, undefined, or empty groupName should be removed from the search parameters to avoid loops and unnecessary clutter
          params.delete('groupName');
        } else if (groupName !== params.get('groupName')) {
          // To avoid unintentional cycle/loops, only update the groupName parameter if it is different from the one already set
          params.set('groupName', groupName);
        }
        return params;
      }),
    isCustomerAuthenticated: auth.hammerstoneGroups?.length > 0,
    isCustomerOnboarded: auth.hammerstoneGroups?.length !== 0,
  };
};

export const useAlias = () => useSelector((state: ReduxState) => state.user.auth.alias);
export const useHammerstoneGroups = () => useSelector((state: ReduxState) => state.user.auth.hammerstoneGroups);
export const useLdapGroups = () => useSelector((state: ReduxState) => state.user.auth.ldapGroups);
// Tracks if the customer is part of a Hammerstone group. On loading state (when undefined) defaults to true for a better customer experience.
export const useIsCustomerOnboarded = () =>
  useSelector(({ user }: ReduxState) => user.auth.hammerstoneGroups?.length !== 0);
export const usePreferences = <T extends keyof UserSliceType['preferences']>(preferenceType: T) =>
  useSelector((state: ReduxState) => state.user.preferences[preferenceType]);

// Page Slice Selectors
export const useHelpPanel = () => useSelector((state: ReduxState) => state.page.helpPanel);
export const useToolsOpen = () => useSelector((state: ReduxState) => state.page.toolsOpen);
export const useGroupSelectorDisabled = () => useSelector((state: ReduxState) => state.page.groupSelectorDisabled);
export const useOldHammerstoneHref = () => useSelector((state: ReduxState) => state.page.oldHammerstoneHref);
export const useFlashbarItems = () => useSelector((state: ReduxState) => state.page.flashbarItems);
export const useFormFieldErrors = () => useSelector((state: ReduxState) => state.page.formFieldErrors);
export const usePipelineCooldown = (pipelineName: string) =>
  useSelector((state: ReduxState) => state.page.pipelineCooldown[pipelineName]);

/**
 * Creates a selector function for a particular dataType, takes dataId as input
 *
 * Selectors are scoped within the object (and NOT around the object) to avoid triggering infinite re-renders
 * which will occur as each selector returns a novel object with a new address.
 *
 *    GOOD (selector within):
 *      {a : useSelector(...), b: useSelector(...), c: useSelector(...)}
 *
 *    BAD (selector around):
 *       useSelector(state => {a: ..., b:..., c:...})
 *
 */
function createDataSelector<D extends DataType>(datatype: D) {
  return (dataId: string) => ({
    data: useSelector(
      (state: ReduxState) => state.data[datatype].data[dataId],
    ) as ReduxState['data'][D]['data'][string],
    fetching: useSelector(
      // Fetching should default to true while waiting for the customer to be authenticated
      (state: ReduxState) => !state.user.auth.hammerstoneGroups?.length || state.data[datatype].fetching[dataId],
    ),
    lastFetched: useSelector((state: ReduxState) => state.data[datatype].lastFetched[dataId]),
  });
}

// Data Slice Selectors
export const useActivity = createDataSelector('activity');
export const useActivityInfoCache = createDataSelector('activityInfoCache');
// Pipeline dataId is formatted as `groupName::pipelineName` in order to enforce uniqueness
const _usePipeline = createDataSelector('pipeline');
export const usePipeline = (...args: Parameters<typeof pipelineIdentifier>) =>
  _usePipeline(pipelineIdentifier(...args));
export const usePipelines = createDataSelector('pipelineNameByGroup');
export const useInstance = createDataSelector('instance');
export const useInstances = createDataSelector('instanceByActivityId');
export const useClusters = createDataSelector('clustersByGroup');
export const useIamRoles = createDataSelector('iamRolesByGroup');
export const useDjsJobId = createDataSelector('djsJobId');
export const useOdinMaterialSets = createDataSelector('odinMaterialSets');

// TODO: Eventually refactor to pipeline cache and disable/block selecting such a pipeline in the Select options
/** A selector which takes in a pipelineId (`groupName::pipelineName`), then a Redux state, and returns the S3 credential type of that pipeline (IAM or ODIN)*/
export const useS3CredentialType = (...args: Parameters<typeof pipelineIdentifier>) => {
  return useSelector((state: ReduxState) => state.data.pipeline.data[pipelineIdentifier(...args)]?.s3CredentialType);
};
// For easy backwards compatibility useFormField was rewritten to use a `react-hook-form` hook
export function useFormField<Type = any>(path: Path<Type>, defaultValue?: any) {
  let value = defaultValue;
  try {
    value = useWatch<Type>({ name: path });
  } catch (error) {}
  return value;
}

// CTI Slice Selectors
export const useCategories = () => useSelector((state: ReduxState) => Object.keys(state.cti ?? {}));
export const useTypes = (category: string) =>
  useSelector((state: ReduxState) => {
    if (category && category in state.cti) {
      return Object.keys(state.cti[category]);
    } else {
      return [];
    }
  });
export const useItems = (category: string, type: string) =>
  useSelector((state: ReduxState) => {
    if (category && type && category in state.cti && type in state.cti[category]) {
      return state.cti[category][type];
    } else {
      return [];
    }
  });
