import { DataType } from '../../interfaces/reduxInterfaces';
import store, { actions } from '../redux';
import * as hsApi from 'src/lib/hammerstoneApi';
import * as repoApi from 'src/lib/repoServiceApi';
import {
  activityPreprocess,
  instancesByActivityPreprocess,
  instanceByIdPreprocess,
  pipelinePreprocess,
} from '../preprocessors';
import { GetGroupPipelineNamesInfoResponse } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { tempFlashbarMessage } from 'src/components/helpers';
import { TimeoutListRef, delay, durationToString } from 'src/commons';
import { pipelineIdentifier } from 'src/interfaces/pipelineInterfaces';

//Currently treating all data types equally, with a cache lifetime of 15 minutes (can refactor: different lifetimes for different datatypes)
export const DATA_SLICE_CACHE_MS = 15 * 60 * 1000;

export function dataIsOutdated(lastFetched: Date | number | string) {
  return new Date().getTime() - new Date(lastFetched).getTime() > DATA_SLICE_CACHE_MS;
}

/**
 * A generic function to retrieve some information from an API and then save it to the redux store
 * @param apiFunc The API function (e.g. hsApi.funcName, repoApi.funcName)
 * @param dataIdFunc A function which takes the input (to the API) and returns a unique string which defines the particular dataId
 * @param datatype A DataType string identifying the kind of object being fetched from the API, e.g. 'activity' | 'pipeline' | etc.
 * @param preprocessor A function which cleans & standardizes the API output to make it React/TS-friendly
 * @param inputNeedsGroupName Whether the input requires a valid `groupName`. Default is true (and should be true for all HS API)
 *
 * @returns A function for fetching information from a Hammerstone API.
 *
 * This function has the following arguments:
 *  - `input`: The `apiFunction` input
 *  - `refresh`: Optional - Whether to retrieve the newest version of the data, regardless of whether it already is cached
 *  - `failQuietlyAfter`: Optional - Whether to display a useful Flashbar notifying the user of a failed API call along with its exception message
 *  - `retries`: Optional - How many times to retry a failed API call. Defaults to 0 (returns error after first failed attempt)
 */
function fetchAPIBuilder<RequestType, ResponseType, T>(
  apiFunc: (input: RequestType) => Promise<ResponseType>,
  dataIdFunc: (input: RequestType) => string,
  datatype: DataType,
  preprocessor?: (output: ResponseType, input?: RequestType) => T,
  inputNeedsGroupName = true,
) {
  return async function (
    input: RequestType,
    refresh = false,
    failQuietly = false,
    retries = 0,
  ): Promise<{ resource: T; refreshed: boolean; error: any }> {
    const state = store.getState();
    const dataId = dataIdFunc(input);

    const dataNeverFetched = !state.data[datatype].data[dataId];
    const dataNeedsFetching =
      !state.data[datatype].fetching[dataId] &&
      (dataNeverFetched || dataIsOutdated(state.data[datatype].lastFetched[dataId]));

    //Checks that the input has a valid groupName associated (should be true of all HS API calls)
    const inputIsValid = inputNeedsGroupName ? !!(input as any)['groupName'] : true;

    if (inputNeedsGroupName && state.user.auth.hammerstoneGroups?.length > 1) {
      // If the input requires a groupName field and the user has more than one hammerstone group, retry the apiFunc call with each of the user's groupNames
      apiFunc = tryAllHammerstoneGroupsWrapper(
        apiFunc as (input: RequestType & { groupName: string }) => Promise<ResponseType>,
        state.user.auth.hammerstoneGroups,
      );
    }

    if (inputIsValid && (refresh || dataNeedsFetching)) {
      store.dispatch(actions.data.initiateFetching({ datatype, dataId }));
      try {
        const output = await retryWithExponentialBackoff(async () => await apiFunc(input), retries);
        const preprocessedData = preprocessor ? preprocessor(output, input) : output;
        store.dispatch(actions.data.setData({ datatype, dataId, data: preprocessedData, dataKeysAsIds: false }));
        // Error is false since the fetch call executed without a failure
        return { resource: preprocessedData as T, refreshed: true, error: false };
      } catch (error) {
        store.dispatch(actions.data.fetchingFailed({ datatype, dataId }));
        if (error && !failQuietly) {
          store.dispatch(
            actions.page.addToFlashbar({
              id: `fetch_${datatype}_${dataId}_fail`,
              message: {
                type: 'error',
                header: error.statusCode && error.code ? `Error ${error.statusCode}: ${error.code}` : 'Error!',
                content: `Fetching ${datatype} with identifier ${dataId} failed: ${error.message}`,
                dismissible: true,
              },
            }),
          );
        }
        return {
          resource: state.data[datatype].data[dataId] as T,
          refreshed: false,
          error: { code: error?.code, statusCode: error?.statusCode, message: error?.message },
        };
      }
    }

    // Error is undefined since the fetch call was not actually executed (not known whether it would have failed)
    return { resource: state.data[datatype].data[dataId] as T, refreshed: false, error: undefined };
  };
}

/**
 * @param func Some asynchronous function which you want to (re)try
 * @param retries The number of times to retry the function before considering the execution a failure
 * @param backoff an object with the `exponent` determining the exponential growth of the backoff (default 1.5)
 * and the `msMultiplier` which is the base number of milliseconds to grow (default 1000 = 1 second)
 * @param currentTry For recursion, which try the function is on
 * @returns The value returned by the async function, or a thrown/null error
 */
async function retryWithExponentialBackoff<T>(
  func: () => Promise<T>,
  retries: number,
  backoff = { exponent: 1.5, msMultiplier: 1000 },
  currentTry = 0,
): Promise<T> {
  try {
    return await func();
  } catch (error) {
    currentTry += 1;
    if (retries > 0 && currentTry < retries) {
      // Exponentially growing delay between calls (currentTry seconds times a factor of 1.5.)
      // This backoff rate is an educated guess, @dansmits found that it usually gave backend time to update before reaching > 3 retries,
      // without feeling too long as a customer
      await delay(currentTry ** backoff.exponent * backoff.msMultiplier);
      // Recurse with incremed currentTry
      return await retryWithExponentialBackoff(func, retries, backoff, currentTry);
    } else {
      throw error;
    }
  }
}

function shouldRetryGroups(error: any) {
  return (
    error.statusCode === 404 &&
    error.code === 'InvalidParameterException' &&
    /The requested .* does not belong to the provided Hammerstone group/i.test(error.message)
  );
}
/**
 * This wrapper maps a HS API Function to a function with the same input/output types which will retry if it fails due to a 404 InvalidParameterException
 *
 * TODO: Rewrite backend errors across resource to specify incorrect groupName
 *
 * @param apiFunc An asynchronous function which takes an input which includes a groupName field
 * @param hammerstoneGroups A list of the user's (other) Hammerstone groups
 * @returns An async function which will try to call apiFunc. If this initial call fails because of a 404 InvalidParameterException, it will retry for every available hammerstoneGroup.
 * If one of those retried calls succeeds, its output is returned and its corresponding input groupName is set to as the user's current Hammerstone group.
 */
function tryAllHammerstoneGroupsWrapper<I extends { groupName: string }, O>(
  apiFunc: (input: I) => Promise<O>,
  hammerstoneGroups: string[],
) {
  return async (input: I) => {
    try {
      const output = await apiFunc(input);
      return output;
    } catch (error) {
      //TODO: Fine-tune the conditions for groupName retry once backend has updated error messages
      if (shouldRetryGroups(error)) {
        const TRYING_MESSAGE_ID = 'trying-all-hammerstone-groups';
        try {
          // Notifies the user that retries are occurring
          store.dispatch(
            actions.page.addToFlashbar({
              id: TRYING_MESSAGE_ID,
              message: {
                loading: true,
                header: `Looking for resource...`,
                content: `Trying all Hammerstone groups (this may take a few moments)`,
              },
            }),
          );

          // Removes the already-tried hammerstone group from the list
          const otherGroups = hammerstoneGroups.filter((groupName) => groupName !== input.groupName);
          // Overwrites the groupName input and then returns the succesful output and its corresponding groupName
          const otherGroupsPromises = otherGroups.map((groupName) =>
            apiFunc({ ...input, groupName }).then((output) => ({ output, groupName })),
          );
          // Awaits and returns the first succesful call in the list of promises and sets the user's hammerstone group to the corresponding groupName
          const { output, groupName } = await Promise.any(otherGroupsPromises);
          // NOTE: groupName was removed from the Redux store. React WANTS us to set the groupName thru a hook (useSearchParams) which would require major refactors
          // This is an ugly but temporary workaround, since this entire functionality will be removed in an upcoming CR which retrieves resource owners directly from Repo
          const url = new URL(window.location as any);
          url.searchParams.set('groupName', groupName);
          window.history.pushState(null, '', url.toString());
          return output;
        } catch (retryError) {
          throw new Error(`The requested resource did not exist within any of the user's Hammerstone groups`);
        } finally {
          store.dispatch(actions.page.removeFromFlashbar(TRYING_MESSAGE_ID));
        }
      } else {
        // If the retry conditions are not met, then throw the error returned from the API
        throw error;
      }
    }
  };
}

export const fetchActivityInfo = fetchAPIBuilder(
  hsApi.GetActivityInfo,
  (input) => input.activityId.toString(),
  'activity',
  (output, input) => {
    const activity = activityPreprocess(output);
    store.dispatch(
      actions.data.setData({
        datatype: 'activityInfoCache',
        dataId: input.activityId.toString(),
        data: {
          activityName: activity.activityName,
          pipelineName: activity.activityNamespace,
        },
        dataKeysAsIds: false,
      }),
    );
    return activity;
  },
);

export const fetchInstancesByActivity = fetchAPIBuilder(
  hsApi.GetInstancesByActivity,
  (input) => input.activityId.toString(),
  'instanceByActivityId',
  instancesByActivityPreprocess,
);

export const fetchInstanceById = fetchAPIBuilder(
  hsApi.GetInstanceById,
  (input) => input.instanceId,
  'instance',
  instanceByIdPreprocess,
);

export const fetchPipelineNames = fetchAPIBuilder(
  hsApi.GetGroupPipelineNamesInfo,
  (input) => input.groupName,
  'pipelineNameByGroup',
  // Converts to list of pipelineNames
  (output: GetGroupPipelineNamesInfoResponse) =>
    (output.pipelineNamesInfoList || []).map(({ pipelineName }: any) => pipelineName as string),
);

export const fetchPipelineInfo = fetchAPIBuilder(
  hsApi.GetPipelineInfo,
  // Pipeline names are only unique within a Hammerstone group
  // dataId is formatted as groupName::pipelineName to enfroce global uniqueness
  (input) => pipelineIdentifier(input),
  'pipeline',
  pipelinePreprocess,
);

export const fetchClustersByGroup = fetchAPIBuilder(
  repoApi.GetClustersByGroup,
  (input) => input.groupName,
  'clustersByGroup',
);

export const fetchIamRolesByGroup = fetchAPIBuilder(
  repoApi.GetIamRolesByGroup,
  (input) => input.groupName,
  'iamRolesByGroup',
);

export const fetchDjsJobId = fetchAPIBuilder(
  repoApi.GetSchedulerActivityId,
  (input) => input.activityId.toString(),
  'djsJobId',
  (output) => output.schedulerId ?? '',
  false,
);

export const fetchMaterialSets = fetchAPIBuilder(
  repoApi.GetGroupMaterialSet,
  (input) => input.groupName,
  'odinMaterialSets',
  (output) =>
    Array.from(new Set((output.GetGroupMaterialSetOutput ?? []).map(({ materialSet }) => materialSet))).sort(),
);

// All of the number constants below are educated guesses, and can be adjusted as necessary

/** 10 seconds of refresh cooldown was determined to avoid throttling issues with DJS
 *
 * - Read more: https://quip-amazon.com/FYXZAuzTvuNz/LLD-Hammerstone-React-DJS-Throttling-on-Last-Execution-Status-and-Dates
 * - Our Sev-2 to DJS team to raise our limits: https://t.corp.amazon.com/V850508775/communication
 * - DJS team's responding CR: https://code.amazon.com/reviews/CR-86924440/revisions/1#/details */
const PIPELINE_REFRESH_COOLDOWN = 10 * 1000;

/** An exponential slowdown rate by which to delay each incremental call as the number of consecutive calls increases.
 * Should not be too large, to avoid creating excessive delays for customers with large pipelines. */
const SLOWDOWN_RATE = 1.1;

/** The delay milliseconds between calls.
 * This delay needs to prevent DJS throttling, overwhelming the API, or flooding the client with calls.  */
const DELAY_BETWEEN_CALLS = 200;

/**
 * A chained fetch function which will retrieve a pipeline, each of its activities, and each each activity's last execution instance (if defined)
 *
 * When a pipeline is fetched, a cooldown period is initiated during which the customer will not be able to refresh the pipeline's statuses thru the UI.
 * This function then calls GetActivityInfo for each activity in the pipeline, with a small delay between each consecutive call
 *
 * This cooldown and delay prevent users from submitting hundreds-to-thousands of DJS requests per second for large pipelines, which would result in DJS throttling Hammerstone
 *
 * - Our Sev-2 to DJS team to raise our limits: https://t.corp.amazon.com/V850508775/communication
 * - DJS team's responding CR: https://code.amazon.com/reviews/CR-86924440/revisions/1#/details
 *
 *
 * @param pipelineName The name of the pipeline to fetch
 * @param groupName The Hammerstone groupname of the pipeline
 * @param timeoutRefs Optional - An object with a `delays` or `cooldowns` TimeoutListRef to help callers manage timeouts
 * @param refresh - Optional - Whether to force-refresh the pipeline, activities, and instances (even if they are already cached)
 */
export async function fetchPipelineActivitiesAndInstances(
  pipelineName: string,
  groupName: string,
  timeoutRefs?: { delays?: TimeoutListRef; cooldowns?: TimeoutListRef },
  refresh?: boolean,
) {
  // First, fetch the pipeline info
  return fetchPipelineInfo(
    {
      pipelineName,
      groupName,
      // Do NOT call DJS from GetPipelineInfo to avoid throttling ! (default is false, but this is made explicit below)
      includeLastExecutionDetails: false,
    },
    refresh,
  ).then((output) => {
    const { resource: pipeline, refreshed } = output;
    if (refresh || refreshed) {
      // Update pipelineCooldown object so that UI prevents user from refreshing (see REFRESH_COOLDOWN docstring above)
      store.dispatch(actions.page.setPipelineCooldown({ pipelineName, cooldown: true }));

      // After the cooldown time passes, update the pipelineCooldown object to allow the user to refresh (if they so desire)
      const cooldownTimeout = setTimeout(() => {
        store.dispatch(actions.page.setPipelineCooldown({ pipelineName, cooldown: false }));
      }, PIPELINE_REFRESH_COOLDOWN);

      // Add the cooldown timeout to the cooldowns refs list (optional)
      timeoutRefs?.cooldowns?.current?.push(cooldownTimeout);

      // If the customer clicked the refresh button, display a flashbar letting them know why refreshing last execution statuses has been disabled for the cooldown duration
      // This flashbar is not displayed on the initial fetch to avoid overwhelming/annoying user
      if (refresh) {
        tempFlashbarMessage(
          {
            id: 'refresh-pipeline-cooldown',
            message: {
              type: 'info',
              header: 'Refreshing pipeline',
              content: `To avoid throttling, refreshing the statuses for pipeline "${pipelineName}" will be disabled for ${durationToString(
                PIPELINE_REFRESH_COOLDOWN,
              )}. Thank you for your patience.`,
              dismissible: true,
            },
          },
          PIPELINE_REFRESH_COOLDOWN,
        );
      }
    }

    // Fetch the ActivityInfo and its corresponding InstanceInfo for each activity in the returned pipeline.
    // To avoid spamming the user with error message, both of these nested fetches are set to fail quietly
    (pipeline?.activityInfoList ?? []).forEach(({ activityId }, ix) => {
      const delayTimeout = setTimeout(
        () => {
          fetchActivityInfo({ activityId, groupName }, refresh, true).then(({ resource: activity }) => {
            if (activity) {
              fetchInstanceById({ activityId, groupName, instanceId: activity.activityInstanceId }, refresh, true);
            }
          });
        },
        // For each consecutive activity, wait a little longer before fetching it to avoid DJS throttling or overwhelming API
        ix ** SLOWDOWN_RATE * DELAY_BETWEEN_CALLS,
      );

      // Add the delay timeout to the delays refs list (optional)
      timeoutRefs?.delays?.current?.push(delayTimeout);
    });
    return output;
  });
}
