import React, { useMemo } from 'react';
import { get } from 'lodash';
import { ColumnLayout, Link } from '@amzn/awsui-components-react';

import { objToOptions } from 'src/commons';
import { Column, ColumnContainer, descriptions } from '../../helpers';
import { Delimiters, Region } from '../../../constants/hammerstoneConstants';
import Activity, { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { ActivityS3CredentialContent } from '../ActivityS3CredentialContent';
import ClusterSelectContent from '../ClusterSelectContent';
import { EXTRACT_ACTIVITY_WIKI, TRANSFORM_ACTIVITY_WIKI } from 'src/constants/staticUrls';
import keys from 'src/constants/hammerstoneConstantKeys';
import { useActivityInfoCache, useGroupName, useS3CredentialType } from 'src/data/redux';
import Content, { Rules } from 'src/components/helpers/content/';
import EmailExpandableFooter from './EmailExpandableFooter';

const CUSTOM_PARAMETERS_PATH = 'config.CUSTOM_PARAMETERS' as const;
// This disableOn object is reused repeatedly for all the parameter switches
const DisableOnCustomParameters = {
  paths: [CUSTOM_PARAMETERS_PATH],
  condition: (activity: Activity<'TRANSFORM'> | Activity<'EXTRACT'>) =>
    get(activity, CUSTOM_PARAMETERS_PATH)?.length > 0,
};

/**
 * An editable container for information about an EXTRACT or TRANSFORM-type activity's S3 Configuration parameters
 */
export default function ExtractS3Container(
  props: ActivityContainerProps & { activityType: keyof typeof keys.ActivityType },
) {
  const RegionOptions = useMemo(() => [{ label: 'Cross region disabled', value: '' }, ...objToOptions(Region)], []);
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  const WIKI_LINK = props.activityType === 'EXTRACT' ? EXTRACT_ACTIVITY_WIKI : TRANSFORM_ACTIVITY_WIKI;
  const groupName = useGroupName();
  const { pipelineName } = useActivityInfoCache(props.activityId)?.data ?? {};
  const s3CredentialType = useS3CredentialType({ groupName, pipelineName });

  return (
    <ColumnContainer
      header={props.header}
      actions={
        <Link variant="info" target="_blank" href={WIKI_LINK}>
          Info
        </Link>
      }
      footer={<EmailExpandableFooter {...contentProps} />}
    >
      <ActivityS3CredentialContent {...contentProps} />
      <ColumnLayout columns={4}>
        <Content.Text
          {...contentProps}
          label="Destination filepath"
          path="config.S3_FILE_PATH"
          infoHelpPanel="DestinationFilepath_EXTRACT_TRANSFORM"
          rules={(l) => ({ required: Rules.required(l), pattern: Rules.pattern.s3Path(l) })}
          placeholder="e.g. s3://hammerstone/<pipeline>/<activity>/..."
        />
        <ClusterSelectContent
          label="Cluster (read-access)"
          access="READ"
          path="clusterName"
          rules={(l) => ({ required: Rules.required(l) })}
          {...contentProps}
        />
        <Content.Number
          {...contentProps}
          label="Schedule offset (days)"
          path="scheduleDateOffset"
          rules={(l) => ({
            required: Rules.required(l),
            min: Rules.min(0, l),
            max: Rules.max(Number.MAX_SAFE_INTEGER, l),
            validate: { int: Rules.isInteger(l) },
          })}
        />
        <Content.Toggle label="Add ACL" infoHelpPanel="AddAcl" path="config.ADD_ACL" {...contentProps} />
        <Content.Toggle label="Encryption" path="config.ENCRYPTION" infoHelpPanel="Encryption" {...contentProps} />
        <Content.Toggle
          label="Publisher"
          path="config.PUBLISH"
          editDescription="In order to use publisher, you must ensure that manifest is enabled in the parameters below"
          disableOn={{
            paths: ['config.MANIFEST', CUSTOM_PARAMETERS_PATH],
            condition: (activity) =>
              !activity.config.MANIFEST && !activity.config.CUSTOM_PARAMETERS?.includes('MANIFEST'),
          }}
          {...contentProps}
        />
        <Content.Text
          {...contentProps}
          label="Encryption key Odin material set (pick one)"
          path="config.ENCRYPT_KEY_ODIN"
          placeholder="(deprecated) Specify an Odin to encrypt bucket data"
          infoHelpPanel="EncryptionOdinAndKMS"
          disableOn={{
            paths: ['config.ENCRYPTION', 'config.KMS_KEY_ID'],
            condition: (activity) => !activity.config.ENCRYPTION || activity.config.KMS_KEY_ID?.length > 0,
          }}
          // Max length comes from https://odin.amazon.com/#search and https://sage.amazon.dev/posts/23693?t=7
          rules={(l) => ({ pattern: Rules.pattern.materialSet(l), maxLength: Rules.maxLength(255, l) })}
          // TODO: Eventually prohibit Odins if Publisher is enabled, to discourage users from using email publisher with Odins.
          // TODO: Odin being deprecated thru Project Ragnarok: https://w.amazon.com/bin/view/Secrets/Ragnarok/
        />
        <Content.Text
          {...contentProps}
          label="KMS Key ID (pick one)"
          path="config.KMS_KEY_ID"
          placeholder="Specify a KMS key to encrypt bucket data."
          infoHelpPanel="EncryptionOdinAndKMS"
          // TODO: Evenutally add useful rules to validate the format of the KMS key
          disableOn={{
            paths: ['config.ENCRYPTION', 'config.ENCRYPT_KEY_ODIN', 'config.PUBLISH', 'activityNamespace'],
            condition: (activity) => {
              return (
                !activity.config.ENCRYPTION ||
                activity.config.ENCRYPT_KEY_ODIN?.length > 0 ||
                activity.config.PUBLISH ||
                // Following the logic of the Ruby website, KMS Key ID can only be enabled for IAM Pipelines
                // This takes the form field value defined for activity namespace, and uses it with the store's current state to determine whether this activity's pipeline uses IAM or ODIN credentials
                s3CredentialType === 'ODIN'
              );
            },
          }}
          // Optional?
        />
      </ColumnLayout>
      <b>
        S3 <code className="custom-code-box">UNLOAD</code> Command Parameters
      </b>
      <ColumnLayout columns={2} variant="text-grid">
        <ColumnLayout columns={2}>
          <Column>
            <Content.Select
              {...contentProps}
              label="Bucket cross-region"
              options={RegionOptions}
              path="config.REGION"
              disableOn={DisableOnCustomParameters}
              filteringType="auto"
              // optional
            />
            <Content.Text
              {...contentProps}
              label="Field delimiter"
              path="config.FIELD_DELIMITER"
              disableOn={DisableOnCustomParameters}
              // TODO: Include infoHelpPanel and a description of constraints and default
              rules={(l) => ({
                // optional
                pattern: Rules.pattern.fieldDelimiter(l),
              })}
              viewTransform={(delim: string) => (
                <>
                  {/* If the value is one of the keys in the `Delimiters` const obj, then render a grey span indicating the kind of delimiter next to it.
                  The purpose of this is to literally spell-out characters that may otherwise be ambiguous or not visible (e.g. "|" -> "| (pipe)") */}
                  {delim}{' '}
                  {delim in Delimiters && (
                    <span className="missing-data">{` (${Delimiters[delim as keyof typeof Delimiters]})`}</span>
                  )}
                </>
              )}
            />
            <Content.Toggle
              {...contentProps}
              label="Manifest"
              path="config.MANIFEST"
              infoHelpPanel="Manifest_EXTRACT_TRANSFORM"
              disableOn={DisableOnCustomParameters}
            />
          </Column>
          <Column>
            <Content.Toggle
              {...contentProps}
              label="Escape option"
              disableOn={DisableOnCustomParameters}
              path="config.ESCAPE_OPTION"
            />
            <Content.Toggle
              {...contentProps}
              label="G-zipped"
              path="config.GZIP"
              disableOn={DisableOnCustomParameters}
            />
            <Content.Toggle
              {...contentProps}
              label="Overwrite"
              disableOn={DisableOnCustomParameters}
              infoHelpPanel="Overwrite"
              path="config.ALLOW_OVERWRITE"
            />
            {props.activityType === 'EXTRACT' && (
              <Content.Toggle
                {...contentProps}
                label="Add quotes"
                disableOn={DisableOnCustomParameters}
                path="config.ADDQUOTES"
              />
            )}
          </Column>
        </ColumnLayout>
        <Content.Textarea
          {...contentProps}
          label="Custom parameters"
          path={CUSTOM_PARAMETERS_PATH}
          // optional
          // TODO: Eventually implement more complete mapping from controlled parameters to custom params
          // TODO: Eventually confirm the valid pattern/allowed characters
          infoHelpPanel="CustomParameters_EXTRACT_TRANSFORM"
          missingText="No custom parameters defined: default configuration used"
          placeholder="Custom UNLOAD parameters will override configuration"
          editDescription={<descriptions.CustomParameterTemplate />}
        />
      </ColumnLayout>
    </ColumnContainer>
  );
}
