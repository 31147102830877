import React, { useEffect } from 'react';
import { Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import {
  GetActivityInfoRequest,
  GetInstanceInfoByActivityIdRequest,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { useNavigate } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';

import ActivityContainerLayout from './ActivityContainerLayout';
import ActivityActionsDropdown from './ActivityActionsDropdown';
import PAGES from '../../nav/Pages';
import MonitorActivity from './MonitorActivity';
import { fetchInstancesByActivity, fetchActivityInfo, fetchInstanceById } from '../../data/api/fetchFromAPI';
import { RefreshButton, HammerstoneAppLayout } from '../helpers';
import { useActivity, useActivityInfoCache, useGroupName } from 'src/data/redux';
import { urlSearchString } from 'src/nav/navHelper';
import { useAddActivityToRecentPages, useConditionalEffect } from 'src/commons';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import { dateRangeToStartAndEndDatetimes } from '../instance/InstanceDateRangePicker';
import ActivityToggleStatusButton from './ActivityToggleStatusButton';
import RerunExecutionInstanceButton from '../instance/RerunExecutionInstanceButton';

export default function ViewActivity({ activityId, action }: { activityId: string; action: 'view' | 'monitor' }) {
  const groupName = useGroupName();
  const { activityName, pipelineName } = useActivityInfoCache(activityId)?.data ?? {};
  const { data: activity, fetching } = useActivity(activityId);

  const [searchParams] = useSearchParams();
  const rawDateRange = searchParams.get('dateRange');
  const rawInstanceStatusId = searchParams.get('instanceStatusId');
  const dateRange = JSON.parse(rawDateRange || '{}');
  const instanceStatusId = parseInt(rawInstanceStatusId) || -1;

  const [startScheduleDateTime, endScheduleDateTime] = dateRangeToStartAndEndDatetimes(dateRange);

  const activityInput: GetActivityInfoRequest = { activityId: parseInt(activityId), groupName };
  const instanceInput: GetInstanceInfoByActivityIdRequest = {
    activityId: parseInt(activityId),
    groupName,
    startScheduleDateTime,
    endScheduleDateTime,
    instanceStatusId,
  };
  const navigate = useNavigate();

  useConditionalEffect(() => {
    fetchActivityInfo(activityInput);
  }, [activityId, groupName]);

  useConditionalEffect(() => {
    // Fetches the activityInstanceId if it is defined
    fetchInstanceById({ instanceId: activity.activityInstanceId, activityId: parseInt(activityId), groupName });
  }, [activityId, groupName, activity?.activityInstanceId]);

  useEffect(() => {
    if (activityId && groupName) {
      // Do a hard refresh of the instances if the monitorActivityPreferences change
      // These values are controlled by the custom InstanceDateRangePicker and InstanceStatusIdSelect Components
      fetchInstancesByActivity(instanceInput, true);
    }
  }, [activityId, groupName, rawDateRange, rawInstanceStatusId]);

  useAddActivityToRecentPages(activityId, activityName, groupName);

  const onRefresh = (e: CustomEvent) => {
    e.preventDefault();
    fetchActivityInfo(activityInput, true);
    fetchInstancesByActivity(instanceInput, true);
    if (activity?.activityInstanceId) {
      // Only try to fetch the last executed instance if we already know the instance ID
      fetchInstanceById({ instanceId: activity.activityInstanceId, activityId: parseInt(activityId), groupName }, true);
    }
  };

  const { activityType } = activity || {};

  const ViewActivityActions = (
    <SpaceBetween direction="horizontal" size="xs">
      <RefreshButton refreshing={fetching} onRefresh={onRefresh} />
      <RerunExecutionInstanceButton
        instanceId={activity?.activityInstanceId}
        activityId={activityId}
        onSuccess={() => {
          fetchActivityInfo({ activityId: parseInt(activityId), groupName }, true);
        }}
      >
        Rerun last execution
      </RerunExecutionInstanceButton>
      <ActivityToggleStatusButton activityId={activityId} />
      <ActivityActionsDropdown activityId={activityId} />
    </SpaceBetween>
  );

  return (
    <HammerstoneAppLayout
      {...(action === 'monitor'
        ? PAGES.MONITOR_ACTIVITY.pageLayoutProps({ activityId, groupName, pipelineName, activityName })
        : PAGES.VIEW_ACTIVITY.pageLayoutProps({ activityId }))}
    >
      <SpaceBetween size="xs">
        <Header variant="h1" actions={ViewActivityActions}>
          {activityName ?? <Skeleton height="1.9em" />}
        </Header>
        <Tabs
          tabs={[
            {
              label: 'Details',
              id: 'details',
              content: (
                <ActivityContainerLayout mode={DisplayMode.View} activityId={activityId} activityType={activityType} />
              ),
            },
            {
              label: 'Monitor',
              id: 'monitor',
              content: <MonitorActivity activityId={activityId} />,
            },
          ]}
          activeTabId={action === 'monitor' ? 'monitor' : 'details'}
          onChange={({ detail }) => {
            if (detail.activeTabId === 'monitor') {
              navigate({
                pathname: PAGES.MONITOR_ACTIVITY.path.replace(':activityId', activityId),
                search: urlSearchString({ groupName }),
              });
            } else {
              navigate({
                pathname: PAGES.VIEW_ACTIVITY.path.replace(':activityId', activityId),
                search: urlSearchString({ groupName }),
              });
            }
          }}
        />
      </SpaceBetween>
    </HammerstoneAppLayout>
  );
}
