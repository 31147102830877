import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { blankDataSlice, DataType } from '../../../interfaces/reduxInterfaces';
import Pipeline from '../../../interfaces/pipelineInterfaces';
import Activity from '../../../interfaces/activityInterfaces';
import InstanceFromActivity from '../../../interfaces/instanceInterfaces';
import { GetInstanceInfoByInstanceIdResponse } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import {
  GetClusterUsingGroupOutput,
  IamRoleAssociation,
} from '@amzn/awsdwhammerstonerepository/clients/dwhammerstonerepositoryservice';
import { OneOrList, ToList } from 'src/interfaces/reactInterfaces';

const initialState = {
  activity: blankDataSlice<Activity>(),
  pipeline: blankDataSlice<Pipeline>(),
  pipelineNameByGroup: blankDataSlice<string[]>(),
  instanceByActivityId: blankDataSlice<InstanceFromActivity[]>(),
  instance: blankDataSlice<GetInstanceInfoByInstanceIdResponse>(),
  activityInfoCache: blankDataSlice<{ pipelineName: string; activityName: string }>(),
  clustersByGroup: blankDataSlice<GetClusterUsingGroupOutput[]>(),
  iamRolesByGroup: blankDataSlice<IamRoleAssociation[]>(),
  djsJobId: blankDataSlice<string>(),
  odinMaterialSets: blankDataSlice<string[]>(),
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetData() {
      return initialState;
    },
    initiateFetching(state, { payload }: PayloadAction<{ datatype: DataType; dataId: OneOrList<string> }>) {
      const { datatype } = payload;
      ToList(payload.dataId).forEach((dataId) => {
        state[datatype].fetching[dataId] = true;
      });
    },
    fetchingFailed(state, { payload }: PayloadAction<{ datatype: DataType; dataId: OneOrList<string> }>) {
      const { datatype } = payload;
      ToList(payload.dataId).forEach((dataId) => {
        state[datatype].fetching[dataId] = false;
        delete state[datatype].data[dataId];
      });
    },
    setData(
      state,
      { payload }: PayloadAction<{ datatype: DataType; dataId: string; data: any; dataKeysAsIds?: boolean }>,
    ) {
      const { datatype, dataId, data, dataKeysAsIds } = payload;
      const now = new Date().toString();
      function _setData(_key: string, _data: any) {
        if (datatype === 'pipeline') {
          _data = mergePipelines_activityListDjsInfo(state[datatype].data[_key], _data);
        }
        state[datatype].data[_key] = _data;
        state[datatype].fetching[_key] = false;
        state[datatype].lastFetched[_key] = now;
      }

      if (dataKeysAsIds) {
        // Treats payload.data as a nested object mapping dataId (key) to their respective datum (value)
        Object.entries(data).forEach(([key, datum]) => {
          _setData(key, datum);
        });
      } else {
        // Treats payload.data as a single datum
        _setData(dataId, data);
      }
    },
    clearData(state, { payload }: PayloadAction<{ datatype: DataType; dataId: OneOrList<string> }>) {
      const { datatype, dataId } = payload;
      ToList(dataId).forEach((dataId) => {
        delete state[datatype].data[dataId];
        delete state[datatype].fetching[dataId];
        delete state[datatype].lastFetched[dataId];
      });
    },
  },
});

/** Returns a pipeline which merges the djs status and date from the activities in the old pipeline to the activities in the new pipeline  */
function mergePipelines_activityListDjsInfo(previous: Pipeline, current: Pipeline): Pipeline {
  // Copy the new pipeline to avoid editing existing objects
  const newPipeline = { ...current };
  // Create a map of activityId-->activities in the old pipeline
  const oldActivitiesMap = Object.fromEntries(
    (previous?.activityInfoList ?? []).map((activity) => [activity.activityId, activity]),
  );
  // Copy the list of activities in the new pipeline to avoid editing existin array (Typescript doesn't like changing arrays as you iterate through them)
  const newActivitiesList = [...(newPipeline?.activityInfoList ?? [])];

  newActivitiesList.map((currActivity, ix) => {
    // The previous pipeline's activity (may be undefined)
    const prevActivity = oldActivitiesMap[currActivity.activityId];
    // If the new execution date is undefined, use the old one
    newPipeline.activityInfoList[ix].djsLastExecutionDate ??= prevActivity?.djsLastExecutionDate;
    // If the new execution status is undefined, set it to the old one
    newPipeline.activityInfoList[ix].djsLastExecutionStatus ??= prevActivity?.djsLastExecutionStatus;
  });
  return newPipeline;
}
