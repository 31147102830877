import React from 'react';
import { fetchActivityInfo } from 'src/data/api/fetchFromAPI';
import { useS3CredentialType, useActivity } from 'src/data/redux';
import PAGES from 'src/nav/Pages';
import { HammerstoneAppLayout } from '../helpers';
import ActivityContainerLayout from './ActivityContainerLayout';
import ReactHookFormWrapper from '../helpers/ReactHookFormWrapper';
import { useConditionalEffect } from 'src/commons';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import { useActivityForm } from './useActivityForm';
import { ActivityFormActions } from 'src/constants/hammerstoneConstants';
import keys from 'src/constants/hammerstoneConstantKeys';

const editAction = keys.ActivityFormActions.copy;

export default function CopyActivity({ activityId }: { activityId: string }) {
  const { data: activity, fetching } = useActivity(activityId);
  const { form, onCancel, onValidSubmit, groupName, ActivateButton } = useActivityForm(editAction, activityId);
  // form watch tracks changes to field values: https://react-hook-form.com/docs/useform/watch
  const [pipelineName, activityType] = form.watch(['activityNamespace', 'activityType']);

  const s3CredentialType = useS3CredentialType({ pipelineName, groupName });

  useConditionalEffect(() => {
    // Asynchronously update the default form fields with the values from the existing activity once it loads, and change its name to avoid conflict
    // https://react-hook-form.com/docs/useform/reset
    form.reset({ ...activity, activityName: activity.activityName + '_copy', scheduleDate: null });
  }, [activity]);

  useConditionalEffect(() => {
    fetchActivityInfo({ groupName, activityId: parseInt(activityId) });
  }, [groupName]);

  return (
    <HammerstoneAppLayout {...PAGES.COPY_ACTIVITY.pageLayoutProps({ activityId })}>
      <ReactHookFormWrapper
        form={form}
        header={`Copy Activity ${activityId}`}
        onCancel={onCancel}
        onValidSubmit={onValidSubmit}
        // Do not allow submission if the user attempts to create a new activity in an Odin pipeline
        disabled={s3CredentialType === 'ODIN'}
        actions={ActivateButton}
        submitName={ActivityFormActions[editAction]}
      >
        <ActivityContainerLayout
          editAction={editAction}
          mode={!activity || fetching ? DisplayMode.Loading : DisplayMode.Edit}
          // Do not pass an activityId since this component is creating a new activity, not updating an existing one
          activityId=""
          activityType={activityType}
        />
      </ReactHookFormWrapper>
    </HammerstoneAppLayout>
  );
}
