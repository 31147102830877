import { Box, Icon, Select, SelectProps } from '@amzn/awsui-components-react';
import React from 'react';
import { useAuth, useGroupSelectorDisabled } from 'src/data/redux';

export function HammerstoneGroupSelector() {
  const { groupName, setGroupName, hammerstoneGroups, isCustomerAuthenticated, isCustomerOnboarded } = useAuth();
  const groupSelectorDisabled = useGroupSelectorDisabled();

  const selectedOption = {
    value: groupName,
    label: isCustomerAuthenticated ? groupName || <b>Select group...</b> : <i>Loading...</i>,
  };
  if (!isCustomerOnboarded) {
    return <></>;
  }

  return (
    <div id="groupSelector">
      <Box padding={{ top: 'xxs', right: 'xs' }}>
        <Icon name="group" variant={groupSelectorDisabled ? 'disabled' : 'normal'} />
      </Box>
      <Select
        selectedOption={selectedOption as SelectProps.Option}
        onChange={({ detail }) => setGroupName(detail.selectedOption.value)}
        options={hammerstoneGroups?.map((value) => ({
          value,
          // TODO: Eventually stylize the Admin group?
          iconName: value === groupName ? 'group-active' : undefined,
        }))}
        filteringType="auto"
        selectedAriaLabel="Selected"
        disabled={groupSelectorDisabled}
      />
    </div>
  );
}
