import { Badge, Header, Link, SpaceBetween, Table } from '@amzn/awsui-components-react';
import React from 'react';
import PAGES from '../../nav/Pages';
import { CustomStatusIndicator, RefreshButton, ToggleableDatetime } from '../helpers';
import { ActivityPriority, ActivityType } from '../../constants/hammerstoneConstants';
import { urlSearchString, useInternalOnFollow } from 'src/nav/navHelper';
import { useGroupName, usePipelineCooldown } from 'src/data/redux';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { msBetweenDates, useConditionalEffect, useTimeoutListRef } from 'src/commons';
import { compareBy } from 'src/commons/componentHelpers';
import { fetchActivityInfo, fetchPipelineActivitiesAndInstances } from 'src/data/api/fetchFromAPI';
import {
  LastExecutionDate,
  LastExecutionStatus,
  ActivityInfoWithStatus,
  useActivitiesWithStatus,
  useIsFetchingActivitiesWithStatus,
} from '../activity/LastActivityExecution';
import RerunExecutionInstanceButton from '../instance/RerunExecutionInstanceButton';

// Due to a Polaris bug, all comparator functions must be declared OUTSIDE of a component in order to work
// Sage Post: https://refresh.sage.amazon.dev/posts/1371292#1371320
const lastExecutionDateComparator = compareBy<ActivityInfoWithStatus>('lastExecutionDate', msBetweenDates);
const updateDateComparator = compareBy<ActivityInfoWithStatus>('updateDate', msBetweenDates);

export default function PipelineActivitiesTable(props: { pipelineName: string; loading?: boolean }) {
  /** A ref to a list of timeout functions, which will be used to clean up remaining delay timeouts upon unmounting the component */
  const delayRefs = useTimeoutListRef();

  const groupName = useGroupName();
  const { pipelineName } = props;
  const activityList = useActivitiesWithStatus({ pipelineName, groupName });
  const fetchingActivitiesWithStatus = useIsFetchingActivitiesWithStatus({ pipelineName, groupName });
  const isCoolingDown = usePipelineCooldown(pipelineName);

  const onFollow = useInternalOnFollow();

  const { items, collectionProps } = useCollection(activityList, {
    sorting: { defaultState: { sortingColumn: { sortingField: 'activityId' }, isDescending: false } },
  });

  useConditionalEffect(() => {
    fetchPipelineActivitiesAndInstances(pipelineName, groupName, { delays: delayRefs }, false);
  }, [pipelineName, groupName]);

  return (
    <Table
      {...collectionProps}
      items={items}
      loading={props.loading}
      data-testid="pipelineActivitiesTable"
      header={
        <Header
          variant="h3"
          actions={
            <RefreshButton
              refreshing={fetchingActivitiesWithStatus}
              disabled={isCoolingDown}
              onRefresh={() =>
                fetchPipelineActivitiesAndInstances(pipelineName, groupName, { delays: delayRefs }, true)
              }
            >
              Status
            </RefreshButton>
          }
        >
          Activities
        </Header>
      }
      columnDefinitions={[
        {
          id: 'activityId',
          header: 'ID',
          sortingField: 'activityId',
          cell: ({ activityId }) => (
            <Link
              onFollow={onFollow}
              href={
                PAGES.VIEW_ACTIVITY.path.replace(':activityId', activityId.toString()) + urlSearchString({ groupName })
              }
            >
              {activityId}
            </Link>
          ),
        },
        {
          id: 'activityName',
          header: 'Name',
          cell: (item) => item.activityName,
          sortingField: 'activityName',
        },
        {
          id: 'activityType',
          header: 'Type',
          cell: (item) => <Badge color="blue">{ActivityType[item.activityType]}</Badge>,
          sortingField: 'activityType',
        },
        {
          id: 'status',
          header: 'Activity status',
          cell: (item) => <CustomStatusIndicator>{item.status}</CustomStatusIndicator>,
          sortingField: 'status',
        },
        {
          id: 'lastExecutionStatus',
          header: 'Last execution status',
          cell: ({ lastExecutionStatus, activity, activityId }) => (
            <SpaceBetween size="xxs" direction="horizontal">
              <LastExecutionStatus lastExecutionStatus={lastExecutionStatus} />{' '}
              {activity?.activityInstanceId && (
                <Link
                  onFollow={onFollow}
                  variant="secondary"
                  href={
                    PAGES.VIEW_INSTANCE.path
                      .replace(':instanceId', activity.activityInstanceId)
                      .replace(':activityId', activityId.toString()) + urlSearchString({ groupName })
                  }
                >
                  (view)
                </Link>
              )}
            </SpaceBetween>
          ),
          sortingField: 'lastExecutionStatus',
        },
        {
          id: 'rerun',
          header: 'Rerun',
          cell: ({ activityId, activity }) => (
            <RerunExecutionInstanceButton
              activityId={activityId?.toString()}
              instanceId={activity?.activityInstanceId}
              variant="inline-icon"
              onSuccess={() => {
                fetchActivityInfo({ activityId, groupName }, true);
              }}
            />
          ),
        },
        {
          id: 'lastExecutionDate',
          header: 'Last execution date',
          // When there is no previous exeuction and when DJS throttles, the API will return nothing. In that case, we set the values to empty strings and display an 'Undetermined' message, as suggested by a customer (ecaglar@)
          cell: ({ lastExecutionDate, status }) => (
            <LastExecutionDate lastExecutionDate={lastExecutionDate} activityStatusCode={status} />
          ),
          sortingComparator: lastExecutionDateComparator,
        },
        {
          id: 'priority',
          header: 'Priority',
          cell: (item) => ActivityPriority[item.activityPriority],
          sortingField: 'activityPriority',
        },
        {
          id: 'updateDate',
          header: 'Update date',
          cell: (item) => <ToggleableDatetime>{item.updateDate}</ToggleableDatetime>,
          sortingComparator: updateDateComparator,
        },
      ]}
    />
  );
}
