import { useEffect, useRef } from 'react';
import keys from 'src/constants/hammerstoneConstantKeys';
import { DateLike } from 'src/interfaces/reactInterfaces';

export function delay(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}

/**
 * The following are helper components for consistently comparing Date objects
 * They eliminate mistakes that may emerge from YYYY-MM-DD vs YYYY/MM/DD comparisons, as well as YYYY/MM/DD vs timestamp (or YYYY/MM/DD HH:mm:SS etc.)
 * These functions will only compare the year/month/day of two date objects (even if further granularity is provided) and are INCLUSIVE
 *
 * For examples, see the code below
 *  */
export const dateBefore = (a: any, b: any) => {
  return new Date(new Date(a).toUTCString()) <= new Date(new Date(b).toUTCString());
};
export const dateAfter = (a: any, b: any) => {
  return new Date(new Date(a).toUTCString()) >= new Date(new Date(b).toUTCString());
};

/**
 * The date regex used by several different structures in the hammerstone API:
 * https://code.amazon.com/packages/AWSHammerstoneExposedRESTfulServiceLambdaModel/blobs/cc2d0a24151e039fe4ada0b9ba67ec80563253cc/--/model/Datatypes.xml#L326,L370,L382,L591
 *
 */
export const hammerstoneAPIDateRegex =
  /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\s+(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/;
/**
 * A more lenient date format (than Hammerstone API) structured in order of descending time
 * The char between the date and time can be either a single space or T. Seconds and milliseconds are optional.
 * Example formats: YYYY-MM-DD HH:mm, YYYY-MM-DDTHH:mm:ss, YYYY-MM-DD HH:mm:SS.sss
 */
export const descendingDateRegex =
  /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])[ T]([0-1]\d|2[0-3]):[0-5]\d(:[0-5]\d(\.\d{1,3})?)?$/;

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const;

/** If ambiguous, sets the default timezone of a date to UTC */
export function ensureUTC(date: DateLike) {
  if (typeof date === 'string') {
    if (hammerstoneAPIDateRegex.test(date) || descendingDateRegex.test(date)) {
      // Although the HS API stores datetimes in UTC, JavaScript's Date object will interpret these as Locale unless explicitly given a timezone
      // Adding a "Z" suffix to YYYY-MM-DD HH:mm and similar formats will mark the time as UTC, preventing inconsistencies
      date += 'Z';
    }
    // TODO: Force other ambiguous time formats to UTC
  }
  return date;
}

/** Converts a number (e.g. Month, Hour) to a leading-zero two-digit string */
function twoDigits(num: number) {
  return `0${num}`.slice(-2);
}

/**
 * A function which converts a date object to a Polaris-inspired string in the format of "Mon DD, YYYY, HH:mm:ss UTC"
 * e.g. "Jan 01, 1970, 00:00:00 UTC"
 *
 * Note that datetime management can become extremely complicated across timezones, especially when accounting for international browsers:
 * For that reason datetimes should ONLY be displayed in UTC (and should be immediately standardized to UTC when pre-processed from an API call)
 *
 * This function is one implementation which should capture the current use-cases of hammerstone API.
 * For some light reading about the perils of international datetime management, please see the following:
 * https://code.amazon.com/packages/AWSDWWheeljackStaticWebsite/blobs/bbb4804c0624847307d5350b0227f56cb94b3ac9/--/src/utils/Time.tsx#L102,L5
 *
 * @param date Some date-like object (PLEASE ENSURE THAT THE INPUT TIME IS ALREADY IN UTC)
 * @returns A sortable, unambiguous UTC time string with the format: YYYY-MM-DD HH:mm:SSZ (the Z indicates UTC)
 */
export function dateToUTCString(date: DateLike) {
  if (
    typeof date === 'undefined' ||
    date === null || // API will return null dates for instances that have never ran before, so we should show nothing instead of Jan 1, 1970
    Number.isNaN(new Date(date).getTime())
  ) {
    return '';
  } else {
    date = new Date(ensureUTC(date));

    /**
     * Polaris/Cloudscape recommends using the following absolute timestamp format:
     *    Month DD, YYYY, hh:mm (UTC+h:mm)
     * Example:
     *    January 01, 1970, 00:00 (UTC+0:00)
     * Documentation:
     *    https://polaris.a2z.com/patterns/general/timestamps/#formats
     *
     * Our datetime format will differ from this in the following respects:
     *    - Abbreviate the month to the 3 initial chars to reduce unnecessarily long datetime strings
     *    - Include seconds in the time to give the user important information about scheduling
     *    - Remove the parentheses in order to make the string parseable (by `Date`) and invertible
     *    - Remove the timezone offset (e.g. UTC+3:30) since all datetimes across hammerstone are exclusively in UTC
     */
    const yearString = date.getUTCFullYear();
    const monthString = MONTHS[date.getUTCMonth()].slice(0, 3);
    const dateString = twoDigits(date.getUTCDate());
    const hourString = twoDigits(date.getUTCHours());
    const minuteString = twoDigits(date.getUTCMinutes());
    const secondString = twoDigits(date.getUTCSeconds());

    return `${monthString} ${dateString}, ${yearString}, ${hourString}:${minuteString}:${secondString} UTC`;
  }
}

/**
 * @param {DateLike} date Some date-like object (make sure there is no time-zone ambiguity!)
 * @returns A string in UTC formatted for Hammerstone API as `YYYY-MM-DD HH:mm:SS`
 */
export function dateToApiString(date: DateLike) {
  return date ? new Date(date).toISOString().slice(0, -5).replace('T', ' ') : undefined;
}

/**
 * Compares two date-like objects a and b for sorting (return difference of their times in ms)
 */
export function msBetweenDates(start: DateLike, end: DateLike) {
  return new Date(end).getTime() - new Date(start).getTime();
}

export const MS_PER_SEC = 1000;
export const SECS_PER_MIN = 60;
export const MINS_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const DAYS_PER_WEEK = 7;
// Ignoring leap-years
export const DAYS_PER_YEAR = 365;
export const MONTHS_PER_YEAR = 12;
// The avg number of days per month in a non-leap-year
export const DAYS_PER_MONTH = DAYS_PER_YEAR / MONTHS_PER_YEAR;

export const TimeUnits = ['millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'year'] as const;
export type UnitType = (typeof TimeUnits)[number];

export const ScheduleTypeToUnit: { [key in keyof typeof keys.ScheduleType]: UnitType } = {
  HOURS: 'hour',
  DAYS: 'day',
  WEEKS: 'week',
  MONTHS: 'month',
  ONETIME: undefined,
};

/**
 * The approximate number of milliseconds per each unit of time in TimeUnits, only use this for approximate conversions on the front-end
 */
const approxMsPer: { [unit in UnitType]: number } = {
  millisecond: 1,
  second: MS_PER_SEC, // 1,000 ms
  minute: MS_PER_SEC * SECS_PER_MIN, // 60,000 ms
  hour: MS_PER_SEC * SECS_PER_MIN * MINS_PER_HOUR, // 3,600,000 ms
  day: MS_PER_SEC * SECS_PER_MIN * MINS_PER_HOUR * HOURS_PER_DAY, // 86,400,000 ms
  week: MS_PER_SEC * SECS_PER_MIN * MINS_PER_HOUR * HOURS_PER_DAY * DAYS_PER_WEEK, // 604,800,000 ms
  month: MS_PER_SEC * SECS_PER_MIN * MINS_PER_HOUR * HOURS_PER_DAY * DAYS_PER_MONTH, // 2,628,002,880 ms
  year: MS_PER_SEC * SECS_PER_MIN * MINS_PER_HOUR * HOURS_PER_DAY * DAYS_PER_YEAR, // 31,536,000,000 ms
};

/**
 * Converts units of time at approximately a millisecond-level accuracy (ignores leap-years)
 */
export function convertTime(fromTime: number, fromUnit: UnitType, toUnit: UnitType): number {
  return (fromTime * approxMsPer[fromUnit]) / approxMsPer[toUnit];
}

/**
 * Converts a count (number) and unit (string) to a string by joining them and adding an "-s" suffix to the unit for plurals (counts other than 1)
 */
function unitCountToString(count: number, unit: UnitType) {
  if (count === 1) {
    return `1 ${unit}`;
  } else {
    return `${count} ${unit}s`;
  }
}

/**
 * @param {number} num The exact number
 * @param {number} decimalPlaces The number of decimal places / precision of the output number (default is 0)
 * @param {'round' | 'floor' | 'ceil'} truncFunc One of the Math functions to truncate a number (default is `floor`)
 * @returns The number truncated to the number of decimal places provided
 */
function decimalToPrecision(num: number, decimalPlaces: number = 0, truncFunc: 'floor' | 'round' | 'ceil' = 'floor') {
  const precision = Math.pow(10, decimalPlaces);
  // Epsilon is the smallest decimal point, adding it helps correctly round very small numbers (reference: https://stackoverflow.com/a/48764436)
  return Math[truncFunc](num * precision * (1 + Number.EPSILON)) / precision;
}

/**
 * A flexible function for converting time to a humanized, readable string (in a single unit)
 *
 * Examples
 *
 *      durationToString(999); // "<1 second"
 *      durationToString(1500); // "1 second"
 *      durationToString(2500, {decimalPlaces: 1 }); // "2.5 seconds"
 *      durationToString(12345678900); // "4 months"
 *      durationToString(12345678900, {toUnits: ['day', 'week', 'year']}), ; // "20 weeks"
 *
 * @param {number} time The amount of time (default unit is ms) to be stringified
 * @param {UnitType} options.fromUnits - Optional: The unit of the input time (default is `milliseconds`)
 * @param {number} options.decimalPlaces - Optional: The number of decimal places to print at the end of the number. Default is 0.
 * @param {number} options.granularity - Optional: The granularity (number of units) to display. Default is 1.
 * @param {UnitType[]} options.toUnits - Optional: An array of the possible output units (default is `[second, minute, hour, day, month, year]` )
 *
 * @returns A humanized, readable string describing the time in the single coarsest non-zero unit
 */
export function durationToString(
  time: number,
  options?: {
    fromUnit?: UnitType;
    decimalPlaces?: number;
    granularity?: number;
    toUnits?: [UnitType, ...UnitType[]];
  },
): string {
  // Sets default values of options
  const fromUnit: UnitType = options?.fromUnit ?? 'millisecond';
  const defaultToUnits: UnitType[] = ['second', 'minute', 'hour', 'day', 'month', 'year'];
  const precision = options?.decimalPlaces ?? 0;
  const granularity = options?.granularity ? (options.granularity > 1 ? options.granularity : 1) : 1;

  // A unique list of units sorted by their granularity
  let toUnits = Array.from(new Set<UnitType>(options?.toUnits ?? defaultToUnits)).sort(
    (unitA, unitB) => approxMsPer[unitA] - approxMsPer[unitB],
  );
  const fewestMs = approxMsPer[toUnits[0]];

  // A helper function which converts the time and unit to a readable string
  function converter(ms: number, unit: UnitType) {
    return unitCountToString(decimalToPrecision(convertTime(ms, 'millisecond', unit), precision), unit);
  }

  // The absolute value of the provided time in milliseconds
  const ms = convertTime(Math.abs(time), fromUnit, 'millisecond');

  if (Number.isNaN(ms ?? NaN)) {
    return 'NaN';
  }
  if (ms < approxMsPer[toUnits[0]]) {
    return `<1 ${toUnits[0]}`;
  }

  for (let u = 0; u < toUnits.length - 1; u++) {
    // Returns the coarsest time unit with an amount >= 1
    if (ms < approxMsPer[toUnits[u + 1]]) {
      const remainder = ms % approxMsPer[toUnits[u]];
      const recurse = granularity > 1 && remainder > fewestMs;
      if (recurse) {
        // Adds "and " to the separator if this is the penultimate granularity or penultimate included unit
        const separator = granularity === 2 || u === 1 ? ' and ' : ' ';
        // Recurse down one level
        return `${converter(ms, toUnits[u])}${separator}${durationToString(remainder, {
          fromUnit,
          decimalPlaces: precision,
          granularity: granularity - 1,
          toUnits: options?.toUnits,
        })}`;
      } else {
        return converter(ms, toUnits[u]);
      }
    }
  }
  // If none of the units match above, default to the coarsest unit
  return converter(ms, toUnits[toUnits.length - 1]);
}

/** Displays the input time relative to now using the `durationToString` function */
export function timeRelativeToNow(time: DateLike) {
  const now = new Date();
  const then = new Date(ensureUTC(time));
  const msBetween = msBetweenDates(now, then);

  const durationString = durationToString(msBetween);
  return msBetween > 0 ? `In ${durationString}` : `${durationString} ago`;
}

/** A Ref to a list of timeouts  */
export type TimeoutListRef = React.MutableRefObject<NodeJS.Timeout[]>;

/** A custom hook which returns a React ref for a list of Timeouts and a cleanup effect to clear each timeout and reset the ref upon dismounting the parent component. */
export function useTimeoutListRef(): TimeoutListRef {
  const timeoutRefs = useRef<NodeJS.Timeout[]>([]);

  useEffect(() => {
    return () => timeoutRefs.current.forEach((timeout) => clearTimeout(timeout));
  }, []);
  return timeoutRefs;
}

/**
 * Truncates the date to the nearest unit **IN UTC**. In other words:
 *  - `second` will truncate to 0 milliseconds (not relevant for API strings)
 *  - `minute` will truncate to 0 seconds
 *  - `hour` will truncate to 0 minutes
 *  - `day` will truncate to 0 hours
 *  - `month` will truncate to the first day of the month
 *  - `year` will truncate to the first month (Jan)
 *
 * @param date The date you want to truncate
 * @param unit The level at which you want to truncate
 * @returns A new truncated date
 */
export function truncateUTCDate(date: DateLike, unit: UnitType) {
  date = new Date(ensureUTC(date));
  switch (unit) {
    case 'year':
      date.setUTCMonth(0);
    case 'month':
      date.setUTCDate(1);
    case 'week':
    // It is inelegant to reset to the beginning of the week, so we treat week like day (below) and truncate the hours but keep the current date
    case 'day':
      date.setUTCHours(0);
    case 'hour':
      date.setUTCMinutes(0);
    case 'minute':
      date.setUTCSeconds(0);
    case 'second':
      date.setUTCMilliseconds(0);
    default:
      break;
  }
  return date;
}

export function unitTypeToDescriptiveNoun(unitType: UnitType) {
  switch (unitType) {
    case 'second':
      return 'Now';
    case 'minute':
      return 'Current minute';
    case 'hour':
      return 'Current hour';
    case 'day':
      return 'Today';
    case 'week':
      return 'Today';
    case 'month':
      return 'Current month';
    case 'year':
      return 'Current year';
    default:
      return 'Now';
  }
}

export function intervalsBetween(
  startDate: DateLike,
  endDate: DateLike,
  scheduleUnit: UnitType,
  interval?: number | string,
): number {
  if (!scheduleUnit) {
    return 0;
  }

  interval = parseInt((interval || 0).toString());
  if (!interval || interval < 1) {
    return 0;
  }
  startDate = new Date(ensureUTC(startDate));
  endDate = new Date(ensureUTC(endDate));

  const intervals =
    scheduleUnit === 'month'
      ? calendarMonthsBetween(startDate, endDate)
      : convertTime(endDate.getTime() - startDate.getTime(), 'millisecond', scheduleUnit);

  return Math.trunc(intervals / interval);
}

/** Takes in a date and returns the UTC year, month, day, hour, minute, second, and ms components, as well as the last day  */
export function breakdownDate(date: DateLike) {
  const d = new Date(ensureUTC(date));
  return {
    date: d,
    year: d.getUTCFullYear(),
    month: d.getUTCMonth() + 1,
    day: d.getUTCDate(),
    hour: d.getUTCHours(),
    minute: d.getUTCMinutes(),
    second: d.getUTCSeconds(),
    millisecond: d.getUTCMilliseconds(),
    lastDateOfMonth: lastDateOfMonth(d),
  };
}

/** Takes in a date and returns the last day of that month (1-indexed) */
export function lastDateOfMonth(date: Date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)).getUTCDate();
}

/** Special month logic that takes leap years and variable month lengths into account. */
export function calendarMonthsBetween(startDate: DateLike, endDate: DateLike): number {
  startDate = new Date(ensureUTC(startDate));
  endDate = new Date(ensureUTC(endDate));

  if (startDate > endDate) {
    return -calendarMonthsBetween(endDate, startDate);
  }

  const start = breakdownDate(startDate);
  const end = breakdownDate(endDate);

  const yearDiff = end.year - start.year;
  const monthDiff = end.month - start.month;
  const months = yearDiff * 12 + monthDiff;

  const maxStartDate = Math.min(end.lastDateOfMonth, start.day);
  let notQuiteFullMonth = false;

  if (end.day < maxStartDate) {
    notQuiteFullMonth = true;
  } else if (end.day == maxStartDate && end.hour < start.hour) {
    notQuiteFullMonth = true;
  } else if (end.day == maxStartDate && end.hour == start.hour && end.minute < start.minute) {
    notQuiteFullMonth = true;
  } else {
    // If the end day, hour, minute are GREATER THAN OR EQUAL to the start date, we
    // consider a full month having elapsed
    notQuiteFullMonth = false;
  }

  return notQuiteFullMonth ? months - 1 : months;
}
