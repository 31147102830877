import { ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Content, Rules } from 'src/components/helpers/content/';
import { DisplayMode } from '../../helpers/content/contentInterfaces';

// TODO: Add more details about how these fields can and should be used thru infoHelpPanels

/**
 * A helper-component of Expandable Email Configuration Content to be displayed for Extract & Load activities
 * @returns
 */
export default function EmailExpandableFooter(props: { mode: DisplayMode; resourceId: string }) {
  const contentProps = { ...props, resourceType: 'activity' } as const;

  return (
    <Content.Toggle
      {...contentProps}
      label="Send email"
      editDescription="In order to send an email, please enable publisher (and manifest) above"
      path="config.SEND_EMAIL"
      disableOn={{ paths: ['config.PUBLISH'], condition: (activity) => !activity.config.PUBLISH }}
      expandable
    >
      <SpaceBetween size="s">
        <ColumnLayout columns={2} variant="text-grid">
          <ColumnLayout columns={2} variant="text-grid">
            <Content.Toggle
              label="Remove multi-part files"
              path="config.DELETE_FILES"
              disableOn={{
                paths: ['config.PUBLISH', 'config.SEND_EMAIL'],
                condition: (activity) => !activity.config.PUBLISH || !activity.config.SEND_EMAIL,
              }}
              {...contentProps}
            />
            <Content.Toggle
              label="Report header"
              path="config.HAS_REPORT_HEADER"
              disableOn={{
                paths: ['config.PUBLISH', 'config.SEND_EMAIL'],
                condition: (activity) => !activity.config.PUBLISH || !activity.config.SEND_EMAIL,
              }}
              {...contentProps}
            />
          </ColumnLayout>
          <Content.Text
            label="Column names (header)"
            path="config.REPORT_HEADER"
            disableOn={{
              paths: ['config.PUBLISH', 'config.SEND_EMAIL', 'config.HAS_REPORT_HEADER'],
              condition: (activity) =>
                !activity.config.PUBLISH || !activity.config.SEND_EMAIL || !activity.config.HAS_REPORT_HEADER,
            }}
            // optional
            {...contentProps}
          />
        </ColumnLayout>
        <ColumnLayout columns={2} variant="text-grid">
          <Content.Text
            label="Recipients"
            path="config.EMAIL_LIST"
            disableOn={{
              paths: ['config.PUBLISH', 'config.SEND_EMAIL'],
              condition: (activity) => !activity.config.PUBLISH || !activity.config.SEND_EMAIL,
            }}
            rules={(l) => ({
              required: Rules.required(l),
              validate: {
                isEmailList: Rules.isHammerstoneEmailList(l),
              },
            })}
            {...contentProps}
          />
          <Content.Text
            label="Subject"
            path="config.EMAIL_SUBJECT"
            disableOn={{
              paths: ['config.PUBLISH', 'config.SEND_EMAIL'],
              condition: (activity) => !activity.config.PUBLISH || !activity.config.SEND_EMAIL,
            }}
            rules={(l) => ({ required: Rules.required(l) })}
            {...contentProps}
          />
        </ColumnLayout>
        <Content.Textarea
          label="Email summary"
          path="config.EMAIL_SUMMARY"
          placeholder="This field is optional ..."
          disableOn={{
            paths: ['config.PUBLISH', 'config.SEND_EMAIL'],
            condition: (activity) => !activity.config.PUBLISH || !activity.config.SEND_EMAIL,
          }}
          {...contentProps}
        />
      </SpaceBetween>
    </Content.Toggle>
  );
}
